import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl/FormikControl";
import moment from "moment";

function TaskForm({
  dialogStatus,
  setDialogStatus,
  getTaskList,
  selectedTask,
  save,
  removeTask
}) {
  const [formValues, setFormValues] = useState(null);
  const [saving, setSavingStatus] = useState(null);
  useEffect(() => {
    if (selectedTask == null) {
      setFormValues({
        task_title: "",
        task_description: "",
        start_date: "",
        end_date: "",
        is_favourite: 0,
      });
    } else {
      selectedTask.start_date = moment(selectedTask.start_date).format(
        "YYYY-MM-DD"
      );
      selectedTask.end_date = moment(selectedTask.end_date).format(
        "YYYY-MM-DD"
      );
      setTimeout(setFormValues(selectedTask), 1000);
    }
  }, [selectedTask]);

  const validationRule = Yup.object({
    task_title: Yup.string().required("Required"),
    task_description: Yup.string().required("Required"),
    start_date: Yup.string().required("Required"),
    end_date: Yup.date().required("Required")
        .when(['start_date'],(start_date,schema,val)=>{
            let errorMsg = "End Date should be greater then Start Date";
            //console.log("val is ",val)
            if(val.value !== undefined){
                console.log("yes here in ")
                return Yup.date().min(start_date,errorMsg);
            }
        }),
  });

  

  const onSubmit = (values,{ resetForm }) => {
    save(values,resetForm);
  };

  return (
    <Dialog
      open={dialogStatus}
      keepMounted
      onClose={() => setDialogStatus(false)}
      maxWidth="sm"
    >
      <DialogTitle>
        {selectedTask == null ? "Create New Task" : "UpdateTask"}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={formValues}
          validationSchema={validationRule}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnMount
        >
          {(formik) => {
            console.log(formik);
            return (
              <Form>
                <div className="row g-3">
                  <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      placeholder="Task Title"
                      label={
                        <img
                          src="/assets/img/icons/event-title.svg"
                          alt="event-title"
                        />
                      }
                      name="task_title"
                    />
                  </div>
                  <div className="col-12">
                    <FormikControl
                      control="bs-textarea"
                      formik={formik}
                      label={<i class="bx bx-bar-chart-alt icon"></i>}
                      name="task_description"
                      placeholder="Description"
                      rows="3"
                    />
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="date"
                      placeholder="Start Date"
                      label={
                        <img
                          src="/assets/img/icons/start-date.svg"
                          alt="start-date"
                        />
                      }
                      name="start_date"
                    />
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="date"
                      placeholder="End Date"
                      label={
                        <img
                          src="/assets/img/icons/end-date.svg"
                          alt="end-date"
                        />
                      }
                      name="end_date"
                    />
                  </div>

                  {saving ? (
                    <div className="col-xxl-12 col-xl-12 col-md-12">
                      <LinearProgress />
                    </div>
                  ) : (
                    <>
                      { selectedTask == null &&
                      <div className="col">
                        <button
                          className="btn btn-outline-primary app-btn"
                          type="button"
                          onClick={() => setDialogStatus(false)}
                        >
                          Cancel
                        </button>
                      </div>
                      }
                      { selectedTask !== null &&
                      <div className="col">
                        <button
                          className="btn btn-outline-danger app-btn"
                          type="button"
                          onClick={()=>removeTask(selectedTask.task_id)}
                        >
                          Delete
                        </button>
                      </div>
                      }
                      <div className="col">
                        <button
                          className="btn btn-primary app-btn app-btn-fade"
                          type="submit"
                        >
                          {selectedTask == null ? "save" : "update"}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default TaskForm;
