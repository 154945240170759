import api from "../../api/api";

const getTaskList = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("get_tasks",payload).then( (res)=>{
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const saveTask = (payload) =>{
    if(payload.task_id) {
        return new Promise((resolve, reject)=>{
            api.post("update_task",payload).then( (res)=>{
                if(res.data.status === "success"){
                    resolve(res.data);
                } else {
                    reject({
                        message: res.data.message
                    })
                }
              }).catch( (error) => {
                reject({
                    message: "Something Went Wrog",
                    error : error
                })
              })
        })
    } else{
        return new Promise((resolve, reject)=>{
            api.post("create_task",payload).then( (res)=>{
                if(res.data.status === "success"){
    
                    resolve(res.data);
                } else {
                    reject({
                        message: res.data.message
                    })
                }
              }).catch( (error) => {
                reject({
                    message: "Something Went Wrog",
                    error : error
                })
              })
        })
    }
}

const removeTask = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("delete_task",payload).then( (res)=>{
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const businessTaskServices = {
    getTaskList,
    saveTask,
    removeTask
}

export default businessTaskServices;