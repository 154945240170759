import React, { useEffect, useState } from 'react'
import { useBusinessFeedData } from '../../../context/BusinessFeedContext'
import BusinessAppLayout from '../../../layout/components/BusinessAppLayout'
import EventRowList from '../components/EventRowList'


function BusinessEventListViewPage() {
    let {businessFeedData} = useBusinessFeedData()
    return (
        <BusinessAppLayout>
            <div className="container">
                {businessFeedData && businessFeedData.event_list &&
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Event Name</th>
                                <th>Event Location</th>
                                <th>Date</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {businessFeedData.event_list.ongoing.length > 0 && 
                                <EventRowList eventList={businessFeedData.event_list.ongoing} />
                            }
                            {businessFeedData.event_list.upcomming.length > 0 && 
                                <EventRowList eventList={businessFeedData.event_list.upcomming} />
                            }
                        </tbody>
                    </table>
                }
            </div>
        </BusinessAppLayout>
    )
}

export default BusinessEventListViewPage