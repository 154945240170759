import api from "../../api/api";


const getBusinessEventFeed = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("v5/business_event_feed",payload).then( (res)=>{
            if(res.data.status === "success"){

                resolve(res.data);
            } else {
                reject({
                    message: res.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const geteventDetailsById = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("event_detail",payload).then( (res)=>{
            if(res.data.status === "success"){

                resolve(res.data);
            } else {
                reject({
                    message: res.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getEventTypes = () => {
    return new Promise((resolve, reject)=>{
        api.post("get_event_types").then( (res)=>{
            if(res.data.status === "success"){

                resolve(res.data);
            } else {
                reject({
                    message: res.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}



const createEvent = (payload) => { // not in use
    return new Promise((resolve, reject)=>{
        api.post("create_event",payload).then( (res)=>{
            if(res.data.status === "success"){

                resolve(res.data);
            } else {
                reject({
                    message: res.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const editEvent = (payload) => {  // not in use
    return new Promise((resolve, reject)=>{
        api.post("edit_event",payload).then( (res)=>{
            if(res.data.status === "success"){

                resolve(res.data);
            } else {
                reject({
                    message: res.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const saveEvent = (payload) =>{
    if(payload.event_id) {
        return new Promise((resolve, reject)=>{
            api.post("edit_event",payload).then( (res)=>{
                if(res.data.status === "success"){
    
                    resolve(res.data);
                } else {
                    reject({
                        message: res.data.data.message
                    })
                }
              }).catch( (error) => {
                reject({
                    message: "Something Went Wrog",
                    error : error
                })
              })
        })
    } else{
        return new Promise((resolve, reject)=>{
            api.post("create_event",payload).then( (res)=>{
                if(res.data.status === "success"){
    
                    resolve(res.data);
                } else {
                    reject({
                        message: res.data.data.message
                    })
                }
              }).catch( (error) => {
                reject({
                    message: "Something Went Wrog",
                    error : error
                })
              })
        })
    }
}


const saveTemplate = (payload) =>{
    if(payload.template_id) {
        return new Promise((resolve, reject)=>{
            api.post("/v2/update_template",payload).then( (res)=>{
                if(res.data.status === "success"){
    
                    resolve(res.data);
                } else {
                    reject({
                        message: res.data.data.message
                    })
                }
              }).catch( (error) => {
                reject({
                    message: "Something Went Wrog",
                    error : error
                })
              })
        })
    } else{
        return new Promise((resolve, reject)=>{
            api.post("/v2/create_template",payload).then( (res)=>{
                if(res.data.status === "success"){
    
                    resolve(res.data);
                } else {
                    reject({
                        message: res.data.data.message
                    })
                }
              }).catch( (error) => {
                reject({
                    message: "Something Went Wrog",
                    error : error
                })
              })
        })
    }
}


const getEventStats = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("v2/event_statistics",payload).then( (res)=>{
            if(res.data.status === "success"){

                resolve(res.data);
            } else {
                reject({
                    message: res.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getEventListWithStats = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("v2/business_events_statistics",payload).then( (res)=>{
            if(res.data.status === "success"){

                resolve(res.data);
            } else {
                reject({
                    message: res.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const businessEventServices = {
    getBusinessEventFeed,
    geteventDetailsById,
    getEventTypes,
    saveEvent,
    saveTemplate,
    getEventStats,
    getEventListWithStats
}

export default businessEventServices;
