import React from "react";
import { Link } from "react-router-dom";

function AuthHeader() {
  return (
    <ul class="download-app">
      <li>
        <Link to="/">
          <h5>Planet</h5>
        </Link>
      </li>
      <li>
        <Link
          to="https://www.planetmobile.app/#cta"
          target="_blank"
          class="btn btn-light px-4"
        >
          Download app
        </Link>
      </li>
    </ul>
  );
}

export default AuthHeader;
