import { Percent } from "@mui/icons-material";
import CircularProgress from "@mui/joy/CircularProgress";
import React from "react";

function ProgressCircle({ value, size = "120px", showPercentage=true,displayValue=false }) {
  return (
    <div className="app-circular">
      <CircularProgress
        determinate
        variant="solid"
        value={value}
        sx={{
          "--CircularProgress-size": size,
          "--CircularProgress-trackThickness": "12px",
          "--CircularProgress-progressThickness": "12px",
        }}
      >
        {displayValue == false ? value : displayValue} {showPercentage==true && <span>%</span>}
      </CircularProgress>
    </div>
  );
}

export default ProgressCircle;
