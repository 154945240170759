import React from "react";
import AuthDevieDisplay from "../../auth/components/AuthDevieDisplay";
import AuthHeader from "../../auth/components/AuthHeader";
import StripeCheckoutComponent from "../components/StripeCheckoutComponent";

function BusinessStripePaymentPage() {
  return (
    <section class={"pt-5 login-areas sign-up-consumer payment-card"}>
      <div class="container">
        <div class="login-box h-auto">
          <div className="w-100">
            <div class="row g-3">
              <div class="col-lg-4 d-flex mx-auto">
                <div class="w-100">
                  <AuthHeader />
                  <StripeCheckoutComponent />
                </div>
              </div>
              {/* <AuthDevieDisplay /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BusinessStripePaymentPage;
