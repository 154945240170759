import React from 'react'
import BusinessFooter from '../sections/BusinessFooter'
import BusinessHeader from '../sections/BusinessHeader'


function BusinessAppLayout({children}) {
    return (
        
        <div className="main" >
            <BusinessHeader/>
                {children}
            <BusinessFooter/>
        </div>
    )
}

export default BusinessAppLayout