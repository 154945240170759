import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
const apiKey = process.env.REACT_APP_MAP_API_KEY;


function GooglePlaceComponent({address,setAddress,latLng,setLatLng}) {
    const [value, setValue] = useState(null);


    useEffect(() => {
        if(value!==null){
            geocodeByAddress(value.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>{
                
                let _latLng = {
                    lat:lat,
                    lng:lng
                };
                setAddress(value.label)
                setLatLng(_latLng)
            });
        }
    }, [value]);

    

    return (
        <div className="custom-selete-event">
          <div className="form-group">
            <label className="icon">
              <img
                src="/assets/img/icons/address.svg"
                alt="event-address"
              />
            </label>
            <GooglePlacesAutocomplete 
                apiKey={apiKey}
                selectProps={{
                    defaultInputValue:address,
                    placeholder: "Address",
                    onChange: setValue,
                }}
            />
          </div>
        </div>
    )
}

export default GooglePlaceComponent