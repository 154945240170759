import React from 'react'
import { BSSwitch } from './CheckboxInput';
import { BSGSelectInput, BSSelectInput, BSSelectInputLG } from './SelectInput';
import {MUInput,BSGInput,BSGPasswordInput, InputBox, BSTextarea} from './TextInput'


function FormikControl(props) {
    const {control, ...rest} = props;
    switch(control){
        case 'mui-input':
              return <MUInput {...rest} />
        case 'bsg-input':
            return <BSGInput {...rest} />
        case 'bsg-password-input':
            return <BSGPasswordInput {...rest} />
        case 'input-box': 
            return <InputBox {...rest} />
        
        case 'bs-switch':
            return <BSSwitch {...rest} /> 

        case 'bs-select':
            return <BSSelectInput {...rest} />
        case 'bsg-select':
            return <BSGSelectInput {...rest} />

        case 'bs-select-lg':
            return <BSSelectInputLG {...rest} />

        case 'bs-textarea':
            return <BSTextarea {...rest} />
        default: return null
    }
}
        
export default FormikControl    