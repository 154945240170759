import { CalendarMonthRounded, PinDrop } from '@mui/icons-material'
import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router';


function EventRowList({eventList}) {
    const navigate = useNavigate();
    const handleEventClick = (event_id) =>{
        navigate("/business/events/details/"+event_id);
    }
    return (
        <>
            {eventList.map(event=>
                <tr>
                    <td>{event.event_id}</td>
                    <td>
                        <img src={event.image} className="event-list-image rounded" onClick={()=>handleEventClick(event.event_id)} style={{cursor:"pointer"}} />
                        <span className="ms-2">{event.event_title}</span>
                    </td>
                    <td className="text-secondary">{event.business_name}</td>
                    <td className="text-secondary">{moment(event.start_date).format("M MMMM, YYYY")}</td>
                    <td className="text-secondary">{moment(event.start_date).format("h:mm A")}</td>
                </tr>
            )}
        </>
    )
}

export default EventRowList