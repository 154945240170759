import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl/FormikControl";
import { LinearProgress } from "@mui/material";
import { useAuth } from "../../../context/AuthContext";
import { useAlert } from "../../../context/AlertContext";
import ImageInput from "../../../components/ImageInput/ImageInput";
import businessSupportServices from "../businessSupportServices";
import AttachmentImage from "../../../components/ImageInput/AttachmentImage";

function ContactSupportForm() {
  const { authUser } = useAuth();
  const { AppAlert } = useAlert();
  const [imageBase64, setImageBase64] = useState(null);
  const [saving, setSavingStatus] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    phone_number: "",
    description: "",
    user_token: authUser.user_token,
  };

  const validationRule = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    phone_number: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    setSavingStatus(true);
    if (imageBase64 !== null) {
      values.ticket_image_base64 = imageBase64;
    }
    businessSupportServices
      .submitQuery(values)
      .then((res) => {
        console.log(res.data);
        AppAlert(res.data.message);
        resetForm();
      })
      .catch((error) => {
        AppAlert(error.message, "error");
      })
      .finally(() => {
        setSavingStatus(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationRule}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnMount
      >
        {(formik) => {
          //console.log(formik);
          return (
            <Form>
              <div className="support-form">
                <div className="row row-cols-1 g-3">
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      placeholder="Name"
                      label={<i class="bx bx-user-circle icon"></i>}
                      name="name"
                    />
                  </div>
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      placeholder="Email"
                      label={<i class="bx bx-envelope icon"></i>}
                      name="email"
                    />
                  </div>
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      placeholder="Phone Number"
                      label={<i class="bx bx-phone icon"></i>}
                      name="phone_number"
                    />
                  </div>
                  <div className="col">
                    <FormikControl
                      control="bs-textarea"
                      formik={formik}
                      type="text"
                      rows="5"
                      placeholder="Description"
                      label={<i class="bx bx-bar-chart-alt icon"></i>}
                      name="description"
                    />
                  </div>
                  <div className="col-12">
                    
                    <AttachmentImage
                      label={"image"}
                      value={null}
                      setImageBase64={setImageBase64}
                    />
                    {/*
                    
                    <div className="support-upload">
                      <div className="form-group">
                        <label for="imgupload" className="form-control">
                          <img
                            src="/assets/img/icons/upload.svg"
                            alt="event-title"
                          />
                          Attach File
                        </label>
                        <input type="file" id="imgupload" name="" className="d-none" />
                      </div>
                    </div>

                    */}
                    
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {saving ? (
                  <LinearProgress />
                ) : (
                  <button
                    className="btn btn-primary app-btn text-capitalize app-btn-fade"
                    type="submit"
                  >
                    Send
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default ContactSupportForm;
