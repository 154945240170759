import { Dialog, DialogContent } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useAlert } from "../../../context/AlertContext";
import { useAuth } from "../../../context/AuthContext";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";
import ContactSupportForm from "../components/ContactSupportForm";
import BusinessAdminLayout from "../layout/BusinessAdminLayout";
import subscriptionServices from "../subscriptionServices";

function BusinessProfilePlanPage() {
  const [subscriptionPackageList, setSubscriptionPackageList] = useState(null);
  const { businessFeedData, getBusinessFeedData } = useBusinessFeedData();
  const { authUser } = useAuth();
  const { AppAlert } = useAlert();

  const [dialogStatus, setDialogStatus] = useState(false);

  const getPackageList = () => {
    subscriptionServices
      .getPackageList(authUser.user_token)
      .then((res) => {
        console.log(res.data.subscription_packages);
        setSubscriptionPackageList(res.data.subscription_packages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPackageList();
  }, [authUser]);

  const navigate = useNavigate();
  const selectPackage = (packageId) => {
    navigate("/business/payment/" + packageId);
  };

  useEffect(() => {
    console.log(businessFeedData.business_profile);
    if (businessFeedData?.business_profile.is_subscribed == 1) {
      //navigate("/")
    }
  }, [businessFeedData]);

  return (
    <section className="login-areas plan-page">
      <div className="container">
        <div className="up-plan">
          <div className="w-100">
            <div className="plan-heaidng">
              <h1>SELECT A PLAN</h1>
              <p>The Choice Is Yours.</p>
            </div>
            <div className="row row-cols-xxl-4 row-cols-xl-4 row-cols-lg-4 row-cols-md-2 g-3">
              {subscriptionPackageList &&
                subscriptionPackageList.map((packageDetails) => (
                  <div className="col d-flex" key={packageDetails.id}>
                    <div className="card">
                      <div className="card-img-top">
                        <h5 className="card-title">{packageDetails.name}</h5>
                      </div>
                      <img
                        src={"/assets/img/" + packageDetails.name + ".svg"}
                      />
                      <div className="card-body bg-white">
                        <h5>
                          {packageDetails.price} <sup>$</sup>
                          <sub>/M</sub>
                        </h5>
                        {packageDetails.name == "Emerald" && (
                          <p className="last-plan mb-1 d-none">
                            You can post as much as you want during your period,
                            but you can only have 14 events up at once. If you
                            already have 14 posts up, you'll need to wait until
                            an event finishes before posting another one.
                          </p>
                        )}
                        <div className="d-flex justify-content-center">
                          <div
                            className={
                              packageDetails.name == "Emerald"
                                ? "plan-text mt-0"
                                : "plan-text"
                            }
                          >
                            <p className="card-text mb-1">
                              <i class="bx bxs-check-circle"></i>
                              {packageDetails.total_allowed_posts} Posts A Month
                            </p>
                            <p className="card-text mb-1">
                              <i class="bx bxs-check-circle"></i>
                              Canva Templates
                            </p>
                            <p className="card-text mb-1">
                              <i class="bx bxs-check-circle"></i>
                              Exclusive to GST
                            </p>
                            {/* <p className="card-text mb-1">
                            <i class="bx bxs-check-circle"></i>
                            {packageDetails.total_allowed_posts} Posts A Month
                          </p> */}
                            {/* <p className="card-text">
                          <i class='bx bxs-check-circle'></i>{packageDetails.total_allowed_events} Events At A
                            Time
                          </p> */}
                          </div>
                        </div>
                        <div class="login-btns plan-btns mt-4">
                          <button
                            className="btn btn-lg text-white"
                            onClick={() => {
                              selectPackage(packageDetails.id);
                            }}
                          >
                            Select
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                <div class="sub-plan-btns">
                  <button
                    className="btn btn-lg mb-3"
                    onClick={() => setDialogStatus(true)}
                  >
                    Contact For Franchise & Premium Businesses
                  </button>
                  {businessFeedData?.business_profile.is_subscribed == 0 && (
                    <button
                      className="btn btn-lg white-plan-btn"
                      onClick={() => {
                        selectPackage(2);
                      }}
                    >
                      Try Out The Silver Package For Free!
                    </button>
                  )}
                  <Dialog
                    open={dialogStatus}
                    keepMounted
                    onClose={() => setDialogStatus(false)}
                    maxWidth="sm"
                  >
                    <DialogContent>
                      <ContactSupportForm />
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BusinessProfilePlanPage;

/*
  const selectPackageOld = (packageId) => {
    let payload = {
      user_token: authUser.user_token,
      package_id: packageId,
      purchase_token: "test",
      sub_transaction_id: "test",
      parent_transaction_id: "test",
      app_type: 1,
    };
    subscriptionServices
      .updateSubscriptionDetails(payload)
      .then((res) => {
        console.log(res);
        AppAlert("Subscription activated successfully");
        getBusinessFeedData()
        
      })
      .catch((error) => {
        console.log(error);
        AppAlert(error.message, "error");
      })
      .finally(() => {

        
      });
  };
  */
