import { Add } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";
import BusinessAdminLayout from "../layout/BusinessAdminLayout";

function BusinessTemplatePage() {
  let { businessFeedData } = useBusinessFeedData();
  const navigate = useNavigate();
  const handleTemplateClick = (template_id) => {
    navigate("/business/template/form/" + template_id);
  };
  const handleCreateTemplateClick = () => {
    navigate("/business/template/form");
  };
  return (
    <BusinessAdminLayout>
      <div class="ongoing-events">
        <div class="top-heading-row">
          <h1 class="web-page-heading">Event Templates</h1>
        </div>
        {businessFeedData &&
          businessFeedData.templates &&
          businessFeedData.templates.business_templates?.map((template) => (
            <div class="card mb-2">
              <div class="card-text-title">
                <h5 class="card-title">{template.template_title}</h5>
              </div>
              <div
                class="card-body"
                key={template.event_id}
                onClick={() => handleTemplateClick(template.template_id)}
                style={{ cursor: "pointer" }}
              >
                <div
                  class="events-cards py-5"
                  style={{
                    background: `linear-gradient(90deg, rgba(0, 0, 0, 0.50) 100%, rgba(0, 0, 0, 0.50) 100%),url(${template.image})`,
                  }}
                >
                  <h1 class="card-title">
                    {template.template_title} {/*<span>TEMPLATE</span>*/}
                  </h1>
                  <p class="card-text">{template.address}</p>
                </div>
              </div>
            </div>
          ))}
        <div class="card">
          <div class="card-text-title">
            <h5 class="card-title">No Template</h5>
          </div>
          <div
            class="card-body"
            style={{ cursor: "pointer" }}
            onClick={handleCreateTemplateClick}
          >
            <div class="no-events-cards py-5">
              <h1 class="card-title">
                CREATE ANOTHER <span>TEMPLATE</span>
              </h1>
              <p class="card-text">No Template</p>
              <img src="/assets/img/add-event-icon.svg" alt="add-event-icon" />
            </div>
          </div>
        </div>
      </div>
    </BusinessAdminLayout>
  );
}

export default BusinessTemplatePage;

/*


                <div className="container">
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <h3 className="ph">Templates</h3>
                        </div>
                        <div className="col-md-6">
                            <Link to={"/business/template/form"} className="btn btn-primary float-end"><Add/> Create Template </Link>
                        </div>

                        <div className="col-md-12">
                            <hr/>
                        </div>
                    </div>
                    <div className="row mt-2">
                        {businessFeedData.templates.business_templates?.map(template=>
                            <div className="col-md-4" key ={template.template_id}>
                                <div className="card">
                                    <img src={template.image!==""?template.image:"http://3.105.164.38/public/uploads/event/picture_not_available.png"} className="event-thumb-image rounded" onClick={()=>handleTemplateClick(template.template_id)} style={{cursor:"pointer"}} />
                                    <div className="card-body">
                                        <h3>{template.template_title}</h3>
                                    </div>
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>

*/
