import { FormControlLabel, Switch } from '@mui/material';

export function MUSwitch(props) {
    const {label, name, formik,rest} = props;
    let val = formik.values[name] == 0 ? false : true;
    return (
        <>
            <FormControlLabel 
                control={
                    <Switch  
                            {...rest}
                            name={name}
                            checked={val}
                            onChange={(event, checked) => {
                                //console.log(event.target.checked);
                                formik.setFieldValue(name, event.target.checked);
                            }}
                    />
                    }
                label={label}
            />
        </>
    )
}


export function BSSwitch(props) {
    const {label, name, formik,rest} = props;
    let val = formik.values[name] == 0 ? false : true;
    return (
        <>
            <div className="form-check form-switch">
                <input className="form-check-input" name={name} checked={val} type="checkbox"

                    onChange={(event)=>formik.setFieldValue(name, event.target.checked)}
          
                />
                <label className="form-check-label">{label}</label>
            </div>
            
        </>
    )
}