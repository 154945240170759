import React from 'react'


function SelectUserType({isBusiness,setIsBusiness}){
    return (
        <div class="text-center">
            <ul class="nav nav-pills">
                <li class="nav-item" >
                    <button onClick={()=>setIsBusiness(0)} className={isBusiness==0?"nav-link active":"nav-link"} type="button">User</button>
                </li>
                <li class="nav-item">
                    <button onClick={()=>setIsBusiness(1)} className={isBusiness==1?"nav-link active":"nav-link"} type="button" >Business</button>
                </li>
            </ul>
        </div>
    )
}


export default SelectUserType