import React from 'react'


import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Key, Lock, LockOpen, LockOutlined, MailOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import authServices from '../authServices';


function UserRegisterPage() {


    const initialValues = {
        username :'',
        password:'',
        is_business:'1',
        device_token:'Web'
    }
    
    const validationRule = Yup.object({
        username: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
    });

    const submitLogin = (values) => {
        console.log("submit",values)
        authServices.attemptLogin(values).then(res=>{
            console.log(res);
        }).catch(error=>{
            console.log(error);
        })
    }

  return (
    <div className="login-bg">
        <div className="row">
            <div className="col-md-4 offset-md-4 col-sm-12">
                <div className="card" style={{borderRadius:15}}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-center">
                                    <div className="planet-logo"></div>
                                </div>
                            </div>
                            <div className="col-md-12 my-3 text-center">
                                <h3>Signup as User</h3>
                            </div>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationRule}
                            onSubmit={submitLogin}
                            enableReinitialize validateOnMount
                            >
                            {
                                (formik) => {
                                //console.log(formik);
                                    return (
                                        <Form>
                                            <div className="row px-5">
                                                <div className="col-md-12 mb-3">
                                                    <FormikControl control="bsg-input" formik={formik} type="text"
                                                        label={<MailOutlined fontSize="small" />} name="username"
                                                    />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <FormikControl control="bsg-password-input" formik={formik} type="password"
                                                        label={<LockOutlined fontSize="small" />} name="password"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    {/* <Link to="/auth/register" className="btn btn-register">Register</Link> */ }
                                                    <div className="row">
                                                        <button disabled={!formik.isValid} type="submit" className="btn btn-primary mt-4 float-end btn-lg">Register</button>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 my-4 text-center">
                                                    <small>Already have an account ? <Link to="/auth/login" className="fw-bold text-dark">Login</Link> </small>
                                                </div>
                                                <div className="mb-5"></div>
                                            </div>

                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default UserRegisterPage