import { CalendarMonthRounded, PinDrop } from '@mui/icons-material'
import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router';

function EventsCardList({eventList}) {
    const navigate = useNavigate();
    const handleEventClick = (event_id) =>{
        navigate("/business/events/details/"+event_id);
    }
    return (
        <>
        {eventList.map(event=>
            
            <div className="card shadow  mb-5 p-2" key={event.event_id}>
                <div className="row">
                    <div className="col-md-5" >
                            <img src={event.image} className="event-thumb-image rounded-3" onClick={()=>handleEventClick(event.event_id)} style={{cursor:"pointer"}} />
                    </div>
                    <div className="col-md-7">
                        <div className="d-flex justify-content-between mt-2">
                            <div className="h-pblue fw-bold py-2" onClick={()=>handleEventClick(event.event_id)} style={{cursor:"pointer"}} >{moment(event.start_date).format("M MMMM, YYYY")} {moment(event.start_date).format("dddd, h:mmA")}</div>
                            <div className="d-flex justify-content-between">
                                <div className="float-start bg-pink p-2 mx-1" style={{borderBottomRightRadius:5}} >
                                    <div className="text-white" style={{fontSize:12}}>AGE</div>
                                    <div className="text-white" style={{fontSize:9}}>{event.min_age} to {event.max_age}</div>
                                </div>
                                <span className="float-end bg-primary p-2" style={{borderTopRightRadius:5,borderBottomLeftRadius:5}} >
                                    <div className="text-white" style={{fontSize:12}}>A $ {event.price}</div>
                                    <div className="text-white" style={{fontSize:9}}>Per Person</div>
                                </span>
                            </div>
                        </div>
                        <div className="">
                            <h6 className="my-2 fw-bold mb-3">{event.event_title}</h6>
                        </div>
                        <div className="mt-3">
                            <span className="text-secondary"><PinDrop/> {event.address} </span>
                        </div>
                        <div className="mt-3">
                            {event.event_description}
                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default EventsCardList