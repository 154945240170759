import React,{useState,useEffect} from 'react'
import { useAuth } from '../../../context/AuthContext';
import { useBusinessFeedData } from '../../../context/BusinessFeedContext';
import businessEventServices from '../businessEventServices';
import ProgressCircle from "../components/ProgressCircle";
import ProgressLinear from "../components/ProgressLinear";
import UpcomingPlaceholder from './UpcomingPlaceholder';

function DashboardUpcommingStats() {
    let { businessFeedData } = useBusinessFeedData()
    const authUser = useAuth()
    const [eventDetails,setEventDetails] = useState(null)
    const [stats,setStats] = useState(null);
    
    useEffect(()=>{
        console.log(businessFeedData)
        if(businessFeedData.event_list.upcomming?.length > 0){
            setEventDetails(businessFeedData.event_list.upcomming[0])
        }
        
    },[businessFeedData])


    

    const getStats = () =>{
        if(eventDetails !== null){
            let payload = {
                event_id : eventDetails.event_id,
                user_token : authUser.authUser.user_token
            }
            businessEventServices.getEventStats(payload).then(res=>{
                setStats(res.data.statistics)
                console.log(res.data);
            }).catch(error=>{
            }).finally(()=>{
            })
        }
    }

    useEffect(()=>{
        getStats();
    },[eventDetails])

    return (
        eventDetails ? 
        <div className="card mb-2">
            <div class="card-text-title">
                <h5 class="card-title">Your Upcoming Event Statistics : {eventDetails.event_title}</h5>
            </div>
            <div className="card-body">
                <div className="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-3">
                    <div className="col">
                        <div className="dashboard-cards">
                            <ul>
                            <li>
                                <ProgressCircle value={stats?.going.percentageForEvent} />
                            </li>
                            <li className="text-end">
                                <h4>
                                <i class="bx bx-user-circle"></i>
                                Going
                                </h4>
                                <h1>{stats?.going.numberOfAttendees}</h1>
                                <p>{stats?.going.percentage}% Up This Event</p>
                            </li>
                            </ul>
                            <ProgressLinear value={stats?.going.percentage} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="dashboard-cards">
                            <ul>
                            <li>
                                <ProgressCircle value={stats?.coverage.percentageForEvent} />
                            </li>
                            <li className="text-end">
                                <h4>
                                <img src="./assets/img/icons/users.svg" alt="" />
                                Coverage
                                </h4>
                                <h1>{stats?.coverage.numberOfAttendees}</h1>
                                <p>{stats?.coverage.percentage}% Up This Event</p>
                            </li>
                            </ul>
                            <ProgressLinear value={stats?.coverage.percentage} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="dashboard-cards">
                            <ul>
                            <li>
                                <ProgressCircle value={stats?.subscribed.percentageForEvent} />
                            </li>
                            <li className="text-end">
                                <p style={{ color: "#D4D4D4" }}>
                                How many people have subscribed to your business
                                </p>
                                <h4>Subscribed</h4>
                                <h1>{stats?.subscribed.numberOfSubscribers}</h1>
                                <p>{stats?.subscribed.percentage}% Up This Event</p>
                            </li>
                            </ul>
                            <ProgressLinear value={stats?.subscribed.percentage} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        <div className="mb-3">
            <UpcomingPlaceholder/>
        </div>
    )
}

export default DashboardUpcommingStats