import React from 'react'

function AuthDevieDisplay() {
    return (
        <div class="col text-center d-flex">
            <div class="w-100">
                <img src="/assets/img/app-ui.svg" alt="app-ui" class="img-fluid app-ui" />
            </div>
        </div>
    )
}

export default AuthDevieDisplay