import { Slider } from "@mui/material";
import React from "react";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";
import DashboardOngoingEvents from "../components/DashboardOngoingEvents";
import DashboardUpcomingEvents from "../components/DashboardUpcomingEvents";
import DashboardUpcommingStats from "../components/DashboardUpcommingStats";

import TaskManager from "../components/TaskManager";
import BusinessAdminLayout from "../layout/BusinessAdminLayout";

function BusinessDashboardPage() {
    let {businessFeedData} = useBusinessFeedData();
  return (
    <BusinessAdminLayout>
      <div class="top-heading-row">
        <h1 class="web-page-heading">Dashboard</h1>
      </div>
      <DashboardUpcommingStats/>
      <div className="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1">
        <DashboardOngoingEvents eventList={businessFeedData.event_list.ongoing} />
        <DashboardUpcomingEvents eventList={businessFeedData.event_list.upcomming} />
        <div className="col">
          <TaskManager />
        </div>
      </div>
    </BusinessAdminLayout>
  );
}

export default BusinessDashboardPage;
