import React,{useState,useEffect} from 'react'

import { Link, NavLink, useNavigate } from 'react-router-dom'
import { AccountCircle, Add, Announcement, BookOnline, CalendarMonthOutlined, Campaign, Chat, Dashboard, DoorSliding, Dvr, FollowTheSigns, Home,  Label,  ListAlt,  ManageAccounts, MoreVert, Newspaper, Notifications, PhoneAndroid, PowerSettingsNew, Settings, Store, Tablet, TransferWithinAStation, Tv, WorkOutlined} from '@mui/icons-material';
import { Alert, Chip } from '@mui/material';
import moment from 'moment';
import BusinessStatisticsPage from '../../app/business/pages/BusinessStatisticsPage';
import { useAuth } from '../../context/AuthContext';

function BusinessHeader() {

    const {authUser } = useAuth();

    //const [authUser,setAuthUser] = useState({role:1,firstName:"Thomas",lastName:"Jackson"})

    return (
        <>
        <div style={{backgroundColor:"#2d3544"}}>
            <div className="container">
                <div className="py-3">
                    <Link className="me-5" to="/"><img src='/planet-text.png' className="ms-2" style={{height:25}} /></Link> {/* navbar-brand  */}
                    <Link to="/auth/logout" className="float-end d-none d-sm-block">
                        <PowerSettingsNew className="ms-2 mt-1 text-pink" sx={{fontSize:30}} />
                    </Link>
                    <div className="float-end">
                        <a  id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}> 
                            <span className="btn btn-pink" style={{borderRadius:"50px"}}>{authUser.name.charAt(0).toUpperCase()}</span> 
                            <small className="ms-2 text-white">{authUser.name} {authUser.lastName}</small>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                            <li><NavLink className="dropdown-item" to="/business/profile">My Profile</NavLink></li> 
                            <li><hr className="dropdown-divider"/></li>
                            <li><NavLink className="dropdown-item" to="/auth/logout">Logout</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <nav className="navbar navbar-expand-lg  navbar-dark  shadow mb-3 p-0" style={{ backgroundColor:"#000" }}>
            <div className="container ">
                <button className="navbar-toggler my-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="collapsibleNavbar">
                    { authUser.roletype === "business" && 
                    
                    <ul className="navbar-nav  mb-2 mb-lg-0 " style={{fontSize:"18px"}}>
                        <li className="nav-item"><NavLink className="nav-link py-4 px-0 mx-4 text-center" area-current="page" to="/business/events"> Events </NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link py-4 px-0 mx-4 text-center" area-current="page" to="/business/events-list-view"> List view </NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link py-4 px-0 mx-4 text-center" area-current="page" to="/business/template">Template </NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link py-4 px-0 mx-4 text-center" area-current="page" to="/business/statistics">Statistics </NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link py-4 px-0 mx-4 text-center" area-current="page" to="/business/profile">Profile </NavLink></li>
                        <li className="nav-item"><NavLink className="btn btn-primary btn-sm rounded-pill mt-3 text-center" area-current="page" to="/business/events/form"><Add sx={{backgroundColor:"#2c79eb", borderRadius:15 }} /> CREATE EVENT </NavLink></li>
                        {/*
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Dropdown</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Link</a></li>
                                <li><a className="dropdown-item" href="#">Another link</a></li>
                                <li><a className="dropdown-item" href="#">A third link</a></li>
                            </ul>
                        </li>
                        */}
                    </ul>
                    }
                </div>
            </div>
        </nav>
        </>
    )
}

export default BusinessHeader