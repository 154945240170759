import React, { useState, useEffect } from "react";
import MapInputComponent from "../components/MapInputComponent";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl/FormikControl";

import businessEventServices from "../businessEventServices";
import ImageInput from "../../../components/ImageInput/ImageInput";
import {LinearProgress} from "@mui/material";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router";
import { useAlert } from "../../../context/AlertContext";
import BusinessAdminLayout from "../layout/BusinessAdminLayout";
import GooglePlaceComponent from "../components/GooglePlaceComponent";
import RangeSelector from "../components/RangeSelector";

function BusinessTemplateUseFormPage() {
    let { businessFeedData, getBusinessFeedData } = useBusinessFeedData();
    const { authUser } = useAuth();
    const { AppAlert } = useAlert();
    const [saving, setSavingStatus] = useState(false);

    // No Formik Perameter
    const [imageBase64, setImageBase64] = useState(null);
    const [rangeValue, setRangeValue] = React.useState([13, 45]);
    const [address, setAddress] = useState(null);
    const [latLng, setLatLng] = useState(null);
    const [createTemplate,setCreatTemplate] = useState(0);

    //Set Event Type List
    const [eventTypes, setEventTypes] = useState(null);
    const getEventTypes = () => {
        businessEventServices
        .getEventTypes()
        .then((res) => {
            setEventTypes(res.data.event_types);
        })
        .catch((error) => {});
    };

    useEffect(() => {
        getEventTypes();
    }, []);

    // Initial Formik Values
    const initialValues = {
        event_title: "",
        start_date: "",
        end_date: "",
        price: "",
        event_type: "",
        event_description: "",
        
        booking_link: "",
        start_time: "",
        end_time: "",
        user_token: authUser.user_token,
    };

    // Validation Rule
    const validationRule = Yup.object({
        event_title: Yup.string().required("Required"),
        start_date: Yup.string().required("Required"),
        end_date: Yup.string().required("Required"),
        price: Yup.string().required("Required"),
        event_type: Yup.string().required("Required"),
        event_description: Yup.string().required("Required"),
        start_time: Yup.string().required("Required"),
        end_date: Yup.date().required("Required")
                .when(['start_date'],(start_date,schema,val)=>{
                    let errorMsg = "End Date should be greater then Start Date";
                    //console.log("val is ",val)
                    if(val.value !== undefined){
                        console.log("yes here in ")
                        return Yup.date().min(start_date,errorMsg);
                    }
                })
    });

  

    //  Edit Work
    const { template_id } = useParams();
    const [formValues, setFormValues] = useState(null);
    const [templateDetails, setTemplateDetails] = useState(null);
    const getTemplateDetails = () => {
        /*
        businessEventServices.gettemplateDetailsById({user_token : authUser.user_token,template_id:template_id,notification_id:"0"}).then(res=>{
            console.log("event_res",res);
        }).catch(error=>{
            console.log("event_error",error);
        })
        */
        setFormValues(null);
        if (businessFeedData !== null) {
        let _templateDetails = businessFeedData.templates.business_templates.find(
            (_template) => _template.template_id == template_id
        );
        setTemplateDetails(_templateDetails);
        if (_templateDetails== null) {
            setAddress("")
        }
        }
    };
    useEffect(() => {
        getTemplateDetails();
    }, [template_id, businessFeedData]);

    useEffect(() => {
        if (templateDetails) {
        let autoFill = {
            //template_id: templateDetails.template_id,
            event_title: templateDetails.template_title,
            start_date: moment(templateDetails.start_date,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
            end_date: moment(templateDetails.end_date,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
            price: templateDetails.price,
            event_type: templateDetails.template_type_id,
            event_description: templateDetails.template_description,
            user_token: authUser.user_token,
            booking_link: templateDetails.booking_link,
            start_time: moment(templateDetails.start_date,"YYYY-MM-DD HH:mm:ss").format("HH:mm"),
            end_time: moment(templateDetails.end_date,"YYYY-MM-DD HH:mm:ss").format("HH:mm"),
        };
        setFormValues(autoFill);
        setAddress(templateDetails.address);
        const _latLng ={
            lat:templateDetails.latitude,
            lng:templateDetails.longitude
        }
        setLatLng(_latLng);
        }
    }, [templateDetails]);

    //Sumit Form 
    const navigate = useNavigate();
    const onSubmit = (values) => {
        if(address===""){
            AppAlert("Event Address Required","error")
            return false
        }else{
            values.address = address;
        }
        setSavingStatus(true);
        values.create_template = createTemplate;
        if (imageBase64 !== null) {
            values.event_image_base64 = imageBase64;
        } else {
            values.event_image_url  = templateDetails.image;
        }

        if (latLng !== null) {
            values.latitude = latLng.lat;
            values.longitude = latLng.lng;
        }
        values.min_age = rangeValue[0];
        values.max_age = rangeValue[1];

        let payload = {...values};
        payload.start_date = values.start_date + " " + values.start_time;
        payload.end_date = values.end_date + " " + values.end_time;
        console.log(payload);

        console.log("template_id", template_id);

        //delete values.start_time;
        //delete values.end_time;
        //delete values.booking_link;

        console.log("save now");
        values.template_id = template_id;

        businessEventServices
        .saveEvent(payload)
        .then((res) => {
            console.log("res->", res);
            if (res.status == "success") {
            getBusinessFeedData();
            console.log("Saved successfully");
            AppAlert(res.data.message);
            setCreatTemplate(0)
            setTimeout(() => {
                //navigate("/business/events/details/"+res.data.event_data.template_id)
                //navigate("/business/events/form/"+res.data.event_data.template_id)
                navigate("/business/template");
            }, [2000]);

            //business/events/details/680
            }
        })
        .catch((error) => {
            console.log(error);
            AppAlert(error.message,"error");
        }).finally(()=>{
            setSavingStatus(false)
        });
    };

    return (
    <BusinessAdminLayout>
        <div class="top-heading-row">
            <h1 class="web-page-heading">
            {template_id == null ? "Create Template" : "Create Event Using Template"}
            </h1>
        </div>
        <div class="event-form-box">
            <div class="card">
                <div class="card-text-title">
                    <h5 class="card-title">
                    {template_id == null ? "Create A New Template" : "Create Event With Template"}
                    </h5>
                </div>
                <div class="card-body">
                    <Formik
                        initialValues={formValues || initialValues}
                        validationSchema={validationRule}
                        onSubmit={onSubmit}
                        enableReinitialize
                        validateOnMount
                    >
                    {(formik) => {
                        console.log(formik);
                        return (
                        <Form>
                            <div className="row g-3">
                            <div className="col-12">
                                <ImageInput
                                label={"Event Banner"}
                                value={templateDetails ? templateDetails.image : null}
                                setImageBase64={setImageBase64}
                                />
                            </div>

                            <div className="col-xxl-4 col-xl-4 col-lg-4">
                                <FormikControl
                                    control="bsg-input"
                                    formik={formik}
                                    type="text"
                                    placeholder="Title"
                                    label={
                                        <img
                                        src="/assets/img/icons/event-title.svg"
                                        alt="event-title"
                                        />
                                    }
                                    name="event_title"
                                />
                            </div>
                            <div className="col-xxl-2 col-xl-2 col-lg-2">
                                <FormikControl
                                    control="bsg-input"
                                    formik={formik}
                                    type="date"
                                    placeholder="Start Date"
                                    label={
                                        <img
                                        src="/assets/img/icons/start-date.svg"
                                        alt="start-date"
                                        />
                                    }
                                    name="start_date"
                                />
                            </div>
                            <div className="col-xxl-2 col-xl-2 col-lg-2">
                                <FormikControl
                                    control="bsg-input"
                                    formik={formik}
                                    type="date"
                                    placeholder="End Date"
                                    label={
                                        <img
                                        src="/assets/img/icons/end-date.svg"
                                        alt="end-date"
                                        />
                                    }
                                    name="end_date"
                                />
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4">
                                <FormikControl
                                    control="bsg-input"
                                    formik={formik}
                                    type="text"
                                    placeholder="Price"
                                    label={
                                        <img
                                        src="/assets/img/icons/price.svg"
                                        alt="event-price"
                                        />
                                    }
                                    name="price"
                                />
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-lg-6">
                                {address !== null &&
                                <GooglePlaceComponent
                                    address={address}
                                    setAddress={setAddress}
                                    latLng={latLng}
                                    setLatLng={setLatLng}
                                />
                                }
                            </div>

                            <div className="col-xxl-2 col-xl-2 col-lg-2">
                                <RangeSelector rangeValue={rangeValue} setRangeValue={setRangeValue} />
                                
                            </div>


                            {eventTypes && (
                                <div className="col-xxl-4 col-xl-4 col-lg-4">
                                <FormikControl
                                    control="bsg-select"
                                    formik={formik}
                                    options={eventTypes}
                                    valueField="event_type_id"
                                    labelField="type_name"
                                    label={
                                    <img
                                        src="/assets/img/icons/mood.svg"
                                        alt="event-mood"
                                    />
                                    }
                                    name="event_type"
                                />
                                </div>
                            )}

                           

                            <div className="col-xxl-8 col-xl-8 col-lg-8">
                                <FormikControl
                                    control="bsg-input"
                                    formik={formik}
                                    type="text"
                                    placeholder="Booking Link"
                                    label={<i class="bx bx-link-alt icon"></i>}
                                    name="booking_link"
                                />
                            </div>
                            <div className="col-xxl-2 col-xl-2 col-lg-2">
                                <FormikControl
                                    control="bsg-input"
                                    formik={formik}
                                    type="time"
                                    placeholder="Start Time"
                                    label={<i class="bx bx-time-five icon"></i>}
                                    name="start_time"
                                />
                            </div>

                            <div className="col-xxl-2 col-xl-2 col-lg-2">
                                <FormikControl
                                    control="bsg-input"
                                    formik={formik}
                                    type="time"
                                    placeholder="End Time"
                                    label={<i class="bx bx-time-five icon"></i>}
                                    name="end_time"
                                />
                            </div>

                            <div className="col-12">
                                <FormikControl
                                    control="bs-textarea"
                                    formik={formik}
                                    label={<i class="bx bx-bar-chart-alt icon"></i>}
                                    name="event_description"
                                    rows="3"
                                    placeholder="Description"
                                />
                            </div>

                                {saving ? 
                                    <div className="col-xxl-12 col-xl-12 col-md-12">
                                        <LinearProgress />
                                    </div>
                                    : 
                                    <>
                                        <div className="col-xxl-12 col-xl-12 col-md-12">
                                            <button className="btn btn-primary app-btn"type="submit">Create</button>
                                        </div>
                                    </>
                                }
                            </div>
                        </Form>
                        );
                    }}
                    </Formik>
                </div>
            </div>
        </div>
    </BusinessAdminLayout>
    );
}

export default BusinessTemplateUseFormPage;