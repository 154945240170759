import React,{useState} from 'react'


import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Key, Lock, LockOpen, LockOutlined, MailOutlined,Engineering } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import authServices from '../authServices';
import { Dialog, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../context/AuthContext';
import { useAlert } from '../../../context/AlertContext';
import SelectUserType from '../components/SelectUserType';
import AuthHeader from '../components/AuthHeader';
import AuthDevieDisplay from '../components/AuthDevieDisplay';
import withAuthLoginLayout from '../sections/withAuthLoginLayout';


function AuthLandingPage1() {
  return (
    <div className="planet-auth-wrap">
        <div className="login-bg">
            <div className="row">
                <div className="col-sm-12 col-md-6 col-xl-6">
                    <div className="auth-section">
                        <AuthHeader />
                        <div className="card bg-light" style={{borderRadius:25}}>
                            <div className="card-body "> 
                                <div className="row">
                                    <div className="col-md-12 text-center mt-5">
                                        <h1 className="text-purple font-spring-demo" style={{lineHeight:0.8,fontWeight:900,fontSize:55}}>YOUR IN POCKET CONCIERGE</h1>
                                        <p className="text-purple font-chronica-pro fw-bold mt-4"><small>Finding What To Do Made Easy.</small></p>
                                    </div>
                                </div>
                                <div className="row text-center mt-3">
                                    <Link to={"/auth/login"} className="btn btn-primary rounded-pill mt-4 float-end p-4">Log In</Link>
                                    <Link to={"/auth/register/business"} className="btn btn-wp rounded-pill p-4 mt-4 float-end btn-lg">Sign Up</Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="device-mobile-block-xl d-none d-xl-block d-xxl-block"></div>
                    <div className="device-mobile-block-lg d-none d-lg-block d-xl-none"></div>
                </div>

            </div>
        </div>
    </div>
  )
}


function AuthLandingPage() {
    return (
        <div class="card">
            <div class="card-body">
                <h1 class="card-title">YOUR IN <br/> POCKET
                    CONCIERGE</h1>
                <p class="card-text">Finding What to Do Made Easy.</p>
                <div class="login-btns">
                    <Link to={"/auth/login"} class="btn btn-lg mb-3"><i class='bx bx-user-circle'></i> Log In</Link>
                    <Link to={"/auth/register/business"} href="sign-up-consumer.html" class="btn btn-lg">Sign Up</Link>
                </div>
            </div>
        </div>
    )

}

export default withAuthLoginLayout(AuthLandingPage)