import api from "../../api/api";

const getPackageList = (token) => {
    return new Promise((resolve, reject)=>{
        let payload  = {
            "user_token":token
        }
        api.post("v5/get_subscription_packages",payload).then( (res)=>{
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getSubscriptionDetails = (token) => {
    return new Promise((resolve, reject)=>{
        let payload  = {
            "user_token":token
        }
        console.log("sending this",payload)
        api.post("v5/get_subscription_details",payload).then( (res)=>{
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateSubscriptionDetails = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("v5/update_subscription_details",payload).then( (res)=>{
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const subscriptionServices = {
    getPackageList,
    getSubscriptionDetails,
    updateSubscriptionDetails
}

export default subscriptionServices;