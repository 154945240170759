import api from "../../api/api";
import axios from 'axios';

//let base_url = "http://megamind-api.appwia.com/";
//let base_url = "http://localhost:8000/";

//let base_url = "https://api.planetnow.io/";
const base_url = process.env.REACT_APP_BASE_URL;

const initiateCheckout = (payload) => {
    return new Promise((resolve, reject)=>{
        axios({
            method: 'post',
            url: base_url+'v5/payment_checkout',
            data: payload
        })
        .then( (res)=>{
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const checkoutStatus = (payload) => {
    return new Promise((resolve, reject)=>{
        axios({
            method: 'post',
            url: base_url+'/v5/checkout_completed',
            data: payload
        })
        .then( (res)=>{
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const stripeServices = {
   initiateCheckout,
   checkoutStatus
}

export default stripeServices;