import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useBusinessFeedData } from '../../../context/BusinessFeedContext'
import BusinessAdminLayout from '../layout/BusinessAdminLayout';
import moment from 'moment';

const OngoingEventsCardList = ({eventList}) => {
    const navigate = useNavigate()
    const handleEventClick = (event_id) =>{
        navigate("/business/events/form/"+event_id);
    }
    return(
        <>
            {eventList.map(event=>
                <div class="card mb-2"  key={event.event_id} onClick={()=>handleEventClick(event.event_id)} style={{cursor:"pointer"}} >
                    <div class="card-text-title">
                        <h5 class="card-title">{event.event_title}</h5>
                    </div>
                    <div class="card-body">
                        <div class="events-cards" 
                                //style="background: linear-gradient(90deg, rgba(0, 0, 0, 0.50) 100%, rgba(0, 0, 0, 0.50) 100%), url('assets/img/island-party-bg.png') no-repeat center / cover;"
                                style={{background: `linear-gradient(90deg, rgba(0, 0, 0, 0.50) 100%, rgba(0, 0, 0, 0.50) 100%),url(${event.image})` }}
                            >
                            <h1 class="card-title">{event.event_title}</h1>
                            <p class="card-text">{event.address}</p>
                            <div class="date-time-event">
                                <p>Date</p>
                                <h1>{moment(event.start_date).format("DD")}<span>/</span>{moment(event.start_date).format("MM")}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
        </>
    )

}

function BusinessOngoingEventsPage() {
    let {businessFeedData} = useBusinessFeedData(); 
    const navigate = useNavigate()
    const handleCreateEvent = () =>{
        navigate("/business/events/form");
    }
    return (
        <BusinessAdminLayout>
                <div class="top-heading-row">
                    <h1 class="web-page-heading">Ongoing Events</h1>
                </div>
                {businessFeedData && businessFeedData.event_list &&
                    <div class="ongoing-events">
                        { businessFeedData.event_list.ongoing.length > 0 && 
                            <OngoingEventsCardList eventList={businessFeedData.event_list.ongoing} />
                        }
                        <div class="card">
                            <div class="card-text-title">
                                <h5 class="card-title">No Event</h5>
                            </div>
                            <div class="card-body" onClick={handleCreateEvent} style={{cursor:"pointer"}}>
                                <div class="no-events-cards">
                                    <h1 class="card-title">CREATE ANOTHER <span>EVENT</span></h1>
                                    <p class="card-text">No Event</p>
                                    <div class="date-time-event">
                                        <p>Date</p>
                                        <h1>00<span>/</span>00</h1>
                                        <div class="position-absolute top-100 start-50 translate-middle">
                                            <img src="/assets/img/add-event-icon.svg" alt="add-event-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            
        </BusinessAdminLayout>
    )
}

export default BusinessOngoingEventsPage