import React, { useEffect, useState } from 'react'
import { useBusinessFeedData } from '../../../context/BusinessFeedContext'
import EventsCardList from '../components/EventsCardList'
import BusinessAdminLayout from '../layout/BusinessAdminLayout';

function BusinessEventsPage() {
    let {businessFeedData} = useBusinessFeedData(); 
    return (
        <BusinessAdminLayout>
            <div className="container">
                {businessFeedData && businessFeedData.event_list &&
                    <div className="row">
                        { businessFeedData.event_list.ongoing.length > 0 && 
                            <div className="col-md-12">
                                <h3 className="ph">Ongoing Events</h3>
                                <hr/>
                                <EventsCardList eventList={businessFeedData.event_list.ongoing} />
                            </div>
                        }
                        { businessFeedData.event_list.upcomming.length > 0 && 
                            <div className="col-md-12">
                                <h3 className="ph">Upcoming Events</h3>
                                <hr/>
                                <EventsCardList eventList={businessFeedData.event_list.upcomming} />
                            </div>
                        }
                    </div>
                }
            </div>
        </BusinessAdminLayout>
    )
}

export default BusinessEventsPage