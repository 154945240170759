import React, { useState } from "react";
import { Field } from "formik";

const hasError = (formik, name) => {
  //console.log(formik.errors[name]);
  //console.log("touched", formik.touched);
  //console.log("formik",formik.values);
  if (formik.errors[name] !== undefined && formik.touched[name] !== undefined) {
    return { error: true, errorMsg: formik.errors[name] };
  } else {
    return { error: false, errorMsg: "" };
  }
};

export function BSSelectInput(props) {
  const { label, name, options, valueField, labelField, formik, ...rest } =
    props;
  return (
    <>
      <Field name={name}>
        {(props) => {
          //console.log(props);
          const { field } = props; //{ field, form, meta }
          const { error, errorMsg } = hasError(formik, name);
          //console.log(formik.values)
          return (
            <>
              <select
                className={
                  error
                    ? "form-select form-select border-danger text-danger"
                    : "form-select form-select"
                }
                {...field}
                onChange={(e) => formik.setFieldValue(name, e.target.value)}
              >
                <option value="">Mood</option>
                {options.map((option) => (
                  <option
                    selected={option[valueField] == formik.values[name]}
                    key={option[valueField]}
                    value={option[valueField]}
                  >
                    {option[labelField]}
                  </option>
                ))}
              </select>
              {error && <small className="text-danger">{errorMsg}</small>}
            </>
          );
        }}
      </Field>
    </>
  );
}

export function BSGSelectInput(props) {
  const { label, name, options, valueField, labelField, formik, ...rest } =
    props;
  return (
    <>
      <Field name={name}>
        {(props) => {
          //console.log(props);
          const { field } = props; //{ field, form, meta }
          const { error, errorMsg } = hasError(formik, name);
          //console.log(formik.values)
          return (
            <>
              <div className="form-group">
                {/* <span className={error?"input-group-text bg-white border-danger text-danger":"input-group-text bg-white"}>{label}</span> */}
                {label}
                <select
                  style={{color:formik.values[name]==""?"#d1cfcf":"#000"}}
                  className={
                    error
                      ? "form-select border-danger"
                      : "form-select"
                  }
                  {...field}
                  onChange={(e) => formik.setFieldValue(name, e.target.value)}
                >
                  <option value="" >Mood</option>
                  {options.map((option) => (
                    <option
                      selected={option[valueField] == formik.values[name]}
                      key={option[valueField]}
                      value={option[valueField]}
                    >
                      {option[labelField]}
                    </option>
                  ))}
                </select>
              </div>
              {error && <small className="text-danger">{errorMsg}</small>}
            </>
          );
        }}
      </Field>
    </>
  );
}
export function BSGSelectInput_old(props) {
  const { label, name, options, valueField, labelField, formik, ...rest } =
    props;
  return (
    <>
      <Field name={name}>
        {(props) => {
          //console.log(props);
          const { field } = props; //{ field, form, meta }
          const { error, errorMsg } = hasError(formik, name);
          //console.log(formik.values)
          return (
            <>
              <div className="input-group">
                <span
                  className={
                    error
                      ? "input-group-text bg-white border-danger text-danger"
                      : "input-group-text bg-white"
                  }
                >
                  {label}
                </span>
                <select
                  className={
                    error
                      ? "form-control border-danger border-start-0"
                      : "form-control border-start-0"
                  }
                  {...field}
                  onChange={(e) => formik.setFieldValue(name, e.target.value)}
                >
                  <option value="">Mood</option>
                  {options.map((option) => (
                    <option
                      selected={option[valueField] == formik.values[name]}
                      key={option[valueField]}
                      value={option[valueField]}
                    >
                      {option[labelField]}
                    </option>
                  ))}
                </select>
              </div>
              {error && <small className="text-danger">{errorMsg}</small>}
            </>
          );
        }}
      </Field>
    </>
  );
}
export function BSSelectInputLG(props) {
  const { label, name, options, valueField, labelField, formik, ...rest } =
    props;
  return (
    <>
      <Field name={name}>
        {(props) => {
          //console.log(props);
          const { field } = props; //{ field, form, meta }
          const { error, errorMsg } = hasError(formik, name);
          //console.log(formik.values)
          return (
            <>
              <select
                className={
                  error
                    ? "form-select form-select border-danger text-danger p-3"
                    : "form-select form-select p-3"
                }
                {...field}
                onChange={(e) => formik.setFieldValue(name, e.target.value)}
              >
                <option value="">Mood</option>
                {options.map((option) => (
                  <option
                    selected={option[valueField] == formik.values[name]}
                    key={option[valueField]}
                    value={option[valueField]}
                  >
                    {option[labelField]}
                  </option>
                ))}
              </select>
              {error && <small className="text-danger">{errorMsg}</small>}
            </>
          );
        }}
      </Field>
    </>
  );
}

export default BSSelectInput;
