import React, { useEffect } from "react";
import { Link, NavLink,useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";

function LeftNavigation() {
  const { authUser } = useAuth();
  let {businessFeedData} = useBusinessFeedData();
  const navigate =  useNavigate()
  useEffect(()=>{
    //console.log(businessFeedData.business_profile)
    if(businessFeedData?.business_profile.is_subscribed == 0){
        navigate("/business/company/profile")
    }
    
  },[businessFeedData])
  return (
    <React.Fragment>
      <div className="sidebar">
        <div className="info-heading">
          <h1 onClick={()=>navigate("/")}>Planet</h1>
          <h5>
            Welcome,
            <br /> {authUser.name} {authUser.lastName}
          </h5>
        </div>
        <div className="menu-bar">
          <ul className="menu-links">
            <li className="nav-link">
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i class="bx bxs-dashboard icon"></i>
                <span className="nav-text">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/business/events/form">
                <i className="bx bx-home-alt icon" />
                <span className="nav-text">Create Event</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/business/statistics">
                <i class="bx bx-objects-vertical-bottom icon"></i>
                <span className="nav-text">Statistics</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/business/events/upcoming">
                <i class="bx bx-calendar icon"></i>
                <span className="nav-text">Upcoming Events</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/business/events/ongoing">
                <i class="bx bx-calendar-event icon"></i>
                <span className="nav-text">Ongoing Events</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/business/template">
                <i class="bx bx-calendar-event icon"></i>
                <span className="nav-text">Event Templates</span>
              </NavLink>
            </li>
          </ul>
          <ul className="menu-links">
            <li className="nav-link">
              <NavLink to="/business/support">
                <i class="bx bx-help-circle icon"></i>
                <span className="nav-text">Support</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/business/profile/form">
              <i class="bx bx-cog icon"></i>
                <span className="nav-text">Settings</span>
              </NavLink>
            </li>
            <li className="nav-link">
              <NavLink to="/auth/logout">
                <i class="bx bx-log-in icon"></i>
                <span className="nav-text">Log Out</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LeftNavigation;
