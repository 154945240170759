import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router';

function DashboardUpcomingEvents({eventList}) {
    const navigate = useNavigate()
    const handleCreateEvent = () =>{
        navigate("/business/events/form");
    }
  return (
    <div className="col">
          <div className="card">
            <div class="card-text-title">
              <h5 class="card-title">Upcoming Events</h5>
            </div>
            <div className="card-body">
                {eventList.map(eventDetails=>
                
                    <div
                        className="dashboard-ue mb-2"
                        style={{
                        background: `linear-gradient(90deg, rgba(0, 0, 0, 0.50) 100%, rgba(0, 0, 0, 0.50) 100%),url(${eventDetails.image})`,
                        }}
                    >
                        <ul>
                        <li>
                            <h5 class="card-subtitle">
                            <i class="bx bx-user-circle"></i> Going
                            </h5>
                            <h3 class="card-title">{eventDetails.statistics.going.numberOfAttendees}</h3>
                            <p class="card-text" style={{ overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis", width:130, }}>{eventDetails.event_title}</p>
                        </li>
                        <li>
                            <div className="date-time-event">
                            <p>Date</p>
                            <h1>
                                {moment(eventDetails.start_date).format("DD")}
                                <span>/</span>
                                {moment(eventDetails.start_date).format("MM")}
                            </h1>
                            </div>
                        </li>
                        </ul>
                        <Link to={"/business/events/form/"+eventDetails.event_id} className="btn app-btn-fade btn-sm mb-2">SEE EVENT</Link>
                        <Link to={"/business/statistics"} className="btn app-btn-fade btn-light btn-sm">STATISTICS</Link>
                        
                    </div>
                )}
                <div onClick={handleCreateEvent} style={{cursor:"pointer"}}>
                    <div class="no-events-cards">
                        <h1 class="card-title">CREATE ANOTHER <span>EVENT</span></h1>
                        <p class="card-text">No Event</p>
                        <div class="date-time-event">
                            <p>Date</p>
                            <h1>00<span>/</span>00</h1>
                            <div class="position-absolute top-100 start-50 translate-middle">
                                <img src="/assets/img/add-event-icon.svg" alt="add-event-icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
  )
}

export default DashboardUpcomingEvents