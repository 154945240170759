import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import { LinearProgress } from '@mui/material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import businessProfileServces from '../businessProfileServces';
import { useAlert } from '../../../context/AlertContext';


function CahngePassword() {
    const {authUser } = useAuth();
    const {AppAlert} = useAlert();
    const [dialogStatus,setDialogStatus] = useState(false);
    const [saving,setSavingStatus] = useState(false);
    

    const initialValues = {
        old_password :'',
        new_password :'',
        confirm_password : '',
        is_business : authUser.roletype === "business" ? 1:0,
        user_token : authUser.user_token,
    }

    const validationRule = Yup.object({
        old_password: Yup.string().required("Required"),
        new_password: Yup.string().required("Required"),
        confirm_password: Yup.string().required("Required").oneOf([Yup.ref('new_password'), null], 'Passwords must match')

    });

    const onSubmit = (values) => {
        console.log(values);
        setSavingStatus(true);
        businessProfileServces.changePassword(values).then(res=>{
            console.log(res);
            AppAlert("Password Updated successfully","success")
            setDialogStatus(false);
        }).catch(error=>{
            console.log(error);
            AppAlert(error.message,"error")
        }).finally(()=>{
            setSavingStatus(false)
        })
    }

    return (
        <>
            <button type="button" className="btn btn-secondary app-btn text-capitalize fw-light" onClick={()=>setDialogStatus(true)}>Change Password</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                maxWidth="sm"
            >
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationRule}
                                onSubmit={onSubmit}
                                enableReinitialize validateOnMount
                                >
                                {
                                    (formik) => {
                                    //console.log(formik);
                                        return (
                                            <Form>
                                                <div className="row mt-3">
                                                    

                                                    <div className="col-md-12 mb-3">
                                                        <FormikControl control="mui-input" formik={formik} type="password" placeholder="Current Password"
                                                            label={"Current password"} name="old_password"
                                                        />
                                                    </div>
                                                    
                                                    <div className="col-md-12 mb-3">
                                                        <FormikControl control="mui-input" formik={formik} type="password" placeholder="New Password"
                                                            label={"New Password"} name="new_password"
                                                        />
                                                    </div>

                                                    <div className="col-md-12 mb-3">
                                                        <FormikControl control="mui-input" formik={formik} type="password" placeholder="Confirm Password"
                                                            label={"Confirm Passowrd"} name="confirm_password"
                                                        />
                                                    </div>
                                                    

                                                    
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {saving ?
                                                            <LinearProgress/>
                                                            :
                                                            <button className="btn btn-primary float-end" type="submit">Submit</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>

                </DialogContent>
            </Dialog>
        </>
    )
}

export default CahngePassword