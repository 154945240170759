import api from "../../api/api";


const setupLocalStorage = (payload) =>{
    localStorage.setItem("authUser",JSON.stringify(payload));
    //console.log("svae this in localstorage", payload);
}

const attemptLogin = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("v2/login",payload).then( (res)=>{
            if(res.data.status === "success"){
                setupLocalStorage(res.data.data.user)
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const registerUser = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("v2/register_user",payload).then( (res)=>{
            if(res.data.status === "success"){
                setupLocalStorage(res.data.data.user)
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const registerBusiness = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("v2/register_business",payload).then( (res)=>{
            if(res.data.status === "success"){
                setupLocalStorage(res.data.data.user)
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const forgotPassword = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("forgot_password",payload).then( (res)=>{
            if(res.data.status === "success"){
                setupLocalStorage(res.data.data.user)
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}



const authServices = {
    attemptLogin,
    registerBusiness,
    registerUser,
    forgotPassword

}

export default authServices;
