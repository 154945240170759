import React, { useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl/FormikControl";
import {
  Key,
  Lock,
  LockOpen,
  LockOutlined,
  MailOutlined,
  Engineering,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import authServices from "../authServices";
import { Dialog, DialogContent } from "@mui/material";
import { useNavigate } from "react-router";
import { useAuth } from "../../../context/AuthContext";
import { useAlert } from "../../../context/AlertContext";
import SelectUserType from "../components/SelectUserType";
import AuthHeader from "../components/AuthHeader";
import withAuthLoginLayout from "../sections/withAuthLoginLayout";

function LoginPage1() {
  const [isBusiness, setIsBusiness] = useState(1);
  const [dialogStatus, setDialogStatus] = useState(false);
  const { setAuthUser, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { AppAlert } = useAlert();

  const initialValues = {
    username: '',   //"arvindb1@gmail.com",
    password: '',   //"arvind",
    remember: true,
    device_token: "Web",
  };

  const validationRule = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const submitLogin = (values) => {
    values.is_business = isBusiness;
    authServices
      .attemptLogin(values)
      .then((res) => {
        if (res.data.user !== undefined) {
          setAuthUser(res.data.user);
          setIsLoggedIn(true);
          navigate("/");
        }
      })
      .catch((error) => {
        AppAlert(error.message, "error");
      });
  };

  return (
    <div className="planet-auth-wrap">
      <div className="login-bg">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xl-6">
            <div className="auth-section">
              <AuthHeader />
              <div className="card bg-light" style={{ borderRadius: 25 }}>
                <div className="card-body ">
                  <div className="row mb-3">
                    <div className="col-md-12 text-center mt-5">
                      <h1
                        className="text-purple font-spring-demo fw-bold"
                        style={{
                          lineHeight: 0.8,
                          fontWeight: 900,
                          fontSize: 55,
                        }}
                      >
                        LOG IN
                      </h1>
                      <p className="text-purple font-chronica-pro fw-bold mt-3">
                        <small>Welcome Back!</small>
                      </p>
                    </div>
                    <SelectUserType
                      isBusiness={isBusiness}
                      setIsBusiness={setIsBusiness}
                    />
                  </div>
                  {isBusiness === 1 && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationRule}
                      onSubmit={submitLogin}
                      enableReinitialize
                      validateOnMount
                    >
                      {(formik) => {
                        //console.log(formik);
                        return (
                          <Form>
                            <div className="row row-cols-xxl-1 row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1 g-2">
                              <div className="col-md-12 mb-3">
                                <FormikControl
                                  control="bsg-input"
                                  formik={formik}
                                  type="text"
                                  label={<MailOutlined fontSize="small" />}
                                  name="username"
                                />
                              </div>
                              <div className="col-md-12 mb-1">
                                <FormikControl
                                  control="bsg-password-input"
                                  formik={formik}
                                  type="password"
                                  label={<LockOutlined fontSize="small" />}
                                  name="password"
                                />
                              </div>
                              {/*
                                                        <div className="col-md-7">
                                                            <FormikControl control="bs-switch" formik={formik} 
                                                                label={<span style={{fontSize:10}}> Remember Me</span> } name="remember"
                                                            />
                                                        </div>
                                                        <div className="col-md-5">
                                                            <Link className="text-dark float-end" to="/auth/forgot-password"> <span style={{fontSize:10}}>Forgot Password?</span></Link>
                                                        </div>
                                                        */}
                              <div className="col-md-12">
                                {/* <Link to="/auth/register" className="btn btn-register">Register</Link> */}
                                <div className="row">
                                  <button
                                    disabled={!formik.isValid}
                                    type="submit"
                                    className="btn btn-primary rounded-pill mt-4 float-end btn-lg"
                                  >
                                    Log In
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-12 my-4 text-center">
                                {/*
                                                            <small>Don't have an account ? 
                                                                <Link className="btn btn-link btn-sm text-dark fw-bold" to="/auth/register/business">Sign UP</Link>
                                                            </small>
                                                            */}
                                <Link
                                  className="text-purple"
                                  to="/auth/forgot-password"
                                >
                                  {" "}
                                  <span style={{ fontSize: 14 }}>
                                    Forgot Password
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                  {isBusiness == 0 && (
                    <div className="row">
                      <div className="col-12 text-center  text-purple">
                        <h2>
                          <Engineering sx={{ fontSize: 80 }} />
                        </h2>
                        <h3 className="font-spring-demo">Coming Soon</h3>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Dialog
                open={dialogStatus}
                keepMounted
                onClose={() => setDialogStatus(false)}
                maxWidth="sm"
              >
                <DialogContent>
                  <div className="row my-3">
                    <Link to="/auth/register/user" className="btn btn-primary">
                      Sign UP as User
                    </Link>
                  </div>
                  <div className="row my-3">
                    <Link
                      to="/auth/register/business"
                      className="btn btn-primary"
                    >
                      Sign UP as Business
                    </Link>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </div>
          <div className="col-md-6">
            <div className="device-mobile-block-xl d-none d-xl-block d-xxl-block"></div>
            <div className="device-mobile-block-lg d-none d-lg-block d-xl-none"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function LoginPage() {
  const [isBusiness, setIsBusiness] = useState(1);
  const [dialogStatus, setDialogStatus] = useState(false);
  const { setAuthUser, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { AppAlert } = useAlert();

  const initialValues = {
    username: '',//"arvindb1@gmail.com",
    password: '',//"arvind",
    remember: true,
    device_token: "Web",
  };

  const validationRule = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const submitLogin = (values) => {
    values.is_business = isBusiness;
    if (isBusiness == 0) {
      setDialogStatus(true);
    } else {
      authServices
        .attemptLogin(values)
        .then((res) => {
          if (res.data.user !== undefined) {
            setAuthUser(res.data.user);
            setIsLoggedIn(true);
            navigate("/");
          }
        })
        .catch((error) => {
          AppAlert(error.message, "error");
        });
    }
  };

  return (
    <>
      <div class="card">
        <div class="card-body">
          <h1 class="card-title mt-4">LOG IN</h1>
          <p class="card-text">Welcome Back!</p>
          <div class="login-tabs my-4">
            <SelectUserType
              isBusiness={isBusiness}
              setIsBusiness={setIsBusiness}
            />
            <Formik
              initialValues={initialValues}
              validationSchema={validationRule}
              onSubmit={submitLogin}
              enableReinitialize
              validateOnMount
            >
              {(formik) => {
                //console.log(formik);
                return (
                  <Form>
                    <div className="row row-cols-xxl-1 row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1 g-2">
                      <div className="col">
                        <FormikControl
                          control="bsg-input"
                          formik={formik}
                          type="text"
                          label={<i class="bx bx-envelope icon"></i>}
                          name="username"
                          placeholder="Email"
                        />
                      </div>
                      <div className="col">
                        <FormikControl
                          control="bsg-input"
                          formik={formik}
                          type="password"
                          label={<i class="bx bx-lock icon"></i>}
                          name="password"
                          placeholder="Password"
                        />
                      </div>
                      {isBusiness == 0 && (
                        <div className="col">
                          <ul class="social-links">
                            <li>
                              <a href="#" class="btn-sm btn">
                                <img
                                  src="/assets/img/google-icon.svg"
                                  alt="google-icon"
                                />{" "}
                                Log In With Google
                              </a>
                            </li>
                            <li>
                              <a href="#" class="btn-sm btn">
                                <img
                                  src="/assets/img/apple-icon.svg"
                                  alt="apple-icon.svg"
                                />
                                Log In With Apple
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                      <div className="col">
                        {/* <Link to="/auth/register" className="btn btn-register">Register</Link> */}
                        <div className="form-group text-center">
                          <button
                            disabled={!formik.isValid}
                            type="submit"
                            className="btn mb-3"
                          >
                            Log In
                          </button>
                          <Link
                            className="text-purple"
                            to="/auth/forgot-password"
                          >
                            Forgot Password
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          {/* <div class="login-btns">
                                        <a href="#" class="btn btn-lg mb-3">Log In</a>
                                        <a href="#">Forgot Password</a>
                                    </div> 
                                    */}
        </div>
      </div>
      <Dialog
        open={dialogStatus}
        keepMounted
        onClose={() => setDialogStatus(false)}
        maxWidth="sm"
      >
        <DialogContent>
          <div className="row">
            <div className="col-12 text-center  text-purple">
              <h2>
                <Engineering sx={{ fontSize: 80 }} />
              </h2>
              <h3 className="font-spring-demo">Coming Soon!</h3>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withAuthLoginLayout(LoginPage);
