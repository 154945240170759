import React from "react";
import AuthDevieDisplay from "../components/AuthDevieDisplay";
import AuthHeader from "../components/AuthHeader";
import { useLocation } from "react-router-dom";

const withAuthLoginLayout = (OriginalComponent) => (props) => {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <section
      class={
        location.pathname == "/auth/register/business"
          ? "login-areas sign-up-consumer pt-5"
          : "login-areas"
      }
    >
      <div class="container">
      <div className="login-box">
          <div className="w-100">
            <div class="row g-3">
              <div class="col-xl-4 col-lg-6 col-md-8 d-flex mx-auto">
                <div class="w-100">
                  <AuthHeader />
                  <OriginalComponent {...props} />
                </div>
              </div>
              {/* <AuthDevieDisplay/> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withAuthLoginLayout;
