import React,{useState,useEffect,useContext} from 'react'
import moment from 'moment';
import businessEventServices from '../app/business/businessEventServices';
import { useAuth } from './AuthContext';

const BusinessFeedDataContext = React.createContext();


export const useBusinessFeedData = () => {
    return useContext(BusinessFeedDataContext);
}

export const BusinessFeedProvider = (props) =>{
    const [businessFeedData,setBusinessFeedData] = useState(null)
    const {authUser } = useAuth();
    const [loading,setLoadingStatus] = useState(true);
    

    const getBusinessFeedData = () =>{
        let payload = {
            "page" : 1,
            "feed_type" : "all",
            "from_date" : moment().format("YYYY-MM-DD HH:mm:ss"),// "2024-03-01 00:00:00",
            "to_date" : moment().format("YYYY-MM-DD HH:mm:ss"),
            "utc_date_time" : moment().format("YYYY-MM-DD HH:mm:ss") ,
            "user_token" : authUser.user_token,
            "limit" : 50
        }
        let _businessFeedData  =  null;//JSON.parse(sessionStorage.getItem("businessFeedData"));
        if(_businessFeedData == null){
            //console.log("get business info from use")
            businessEventServices.getBusinessEventFeed(payload).then(res=>{
                console.log(res.data);
                setBusinessFeedData(res.data.feed_data);
                sessionStorage.setItem("businessFeedData",JSON.stringify(res.data.feed_data))
            }).catch(error=>{})
        } else {
            //console.log("_businessInfo is ",_businessFeedData)
            setBusinessFeedData(_businessFeedData);
        }
    }

    const value= {
        businessFeedData,
        getBusinessFeedData
    }


    useEffect(()=>{
        getBusinessFeedData()
        setTimeout(()=>{
            setLoadingStatus(false);
        },5000)
    },[])
    /*return(
        <>
        {loading ?
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <img src='/images/g-token-logo.gif' style={{width:"50%"}} />
                    </div>
                </div>
            </div>
            :
            <AuthContext.Provider value= {value}>{props.children}</AuthContext.Provider>
        }
        </>
    )
    */
    return  businessFeedData && <BusinessFeedDataContext.Provider value={value}>{props.children}</BusinessFeedDataContext.Provider>
        
    
    
}
