import { CameraAlt, Edit, PersonAddAlt } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState, useEffect } from "react";

import "./ImageInput.css";

const isImage = (file) => {
  if (file.type.match("image.*")) {
    return true;
  }
  return false;
};

function ImageInput({ label, value, setImageBase64 }) {
  const [imagePath, setImagePath] = useState(null);
  const [dialogStatus, setDialogStatus] = useState(false);

  const [displayName,setDisplayName]    = useState("");

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      let _img64 = reader.result;
      setImagePath(_img64);
      setImageBase64(_img64);
      setDialogStatus(false);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const selectFile = (e) => {

    console.log("here ->",e.target.files[0].name)
    if (!isImage(e.target.files[0])) {
        return false;
    }
    setDisplayName(e.target.files[0].name);
    getBase64(e.target.files[0]);
  };

  useEffect(() => {
    console.log(value);
    if (value) {
      setImagePath(value);
    } else {
        setImagePath("/images/blank-profile.svg");
    }
  }, [value]);

  return (
    <>
      <div className="v2-img-container">

          <div className="upload-icon form-group">
            {/* <button
              type="button"
              className="btn"
              onClick={() => setDialogStatus(true)}
            ></button> */}
            <input
              type="file"
              onChange={(e) => {
                selectFile(e);
              }}
              accept="image/png, image/gif, image/jpeg"
              id="imgupload"
              className="d-none"
            />
            {/* <div className="text-secondary bg-white p-1"><CameraAlt /> </div> */}
            <label for="imgupload" className="form-control">
                <img
                  src="/assets/img/icons/upload.svg"
                  alt="event-title"
                />
                {displayName ==="" ? 
                    <span className="text-label">Attach File</span> 
                    : 
                    <span className="text-dark">{displayName}</span> 
                }
            </label>
          </div>
        
      </div>

      {/* <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle><span className='text-primary'>{label}</span></DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className='col-md-6'>
                        </div>
                        <div className="col-md-6">
                            <span className=''> 
                                <input type="file"
                                    className='form-control mt-2'
                                    onChange={ (e)=>{ selectFile(e) } } 
                                    accept="image/png, image/gif, image/jpeg"  
                                /> 
                            </span>
                        </div>
                    </div>
                </DialogContent>
            </Dialog> */}
    </>
  );
}

export default ImageInput;
