import React, { useState } from "react";
import { useAlert } from "../../../context/AlertContext";
import authServices from "../authServices";
import AuthHeader from "../components/AuthHeader";
import withAuthLoginLayout from "../sections/withAuthLoginLayout";

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const { AppAlert } = useAlert();

  const onSubmit = () => {
    authServices
      .forgotPassword({ email: email, is_business: "1" })
      .then((res) => {
        console.log(res);
        //AppAlert("Password Reset")
      })
      .catch((error) => {
        AppAlert(error.message, "error");
      });
  };

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="mb-4">
            <h1 class="card-title mt-4">Forgot Password</h1>
            <p class="card-text">Welcome Back!</p>
          </div>
          <div className="row row-cols-xxl-1 row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1 g-2">
            <div className="col">
              <div className="form-group">
                <i class="bx bx-envelope icon"></i>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary app-btn-fade border-0"
                  // disabled={email === ""}
                  onClick={onSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withAuthLoginLayout(ForgotPasswordPage);
