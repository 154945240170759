import api from "../../api/api";


const changePassword = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("change_password",payload).then( (res)=>{
            console.log(res.data);
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const updateProfile = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("v2/edit_business",payload).then( (res)=>{
            console.log(res.data);
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const removeAccount = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("delete_business",payload).then( (res)=>{
            console.log(res.data);
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}



const businessProfileServces = {
    changePassword,
    updateProfile,
    removeAccount
}

export default businessProfileServces;
