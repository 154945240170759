import { Slider } from "@mui/material";
import React,{useState,useEffect} from "react";

function ProgressLinear({ value }) {

    const marks = [
        {
          value: -100,
          label: '-100 %',
        },
        {
          value: 0,
          label: '0 %',
        },
    ];


    const [myVal,setMyVal] = useState(0)
    useEffect(()=>{
        //console.log("progress value :", value)
        if(value !== undefined){
            setMyVal(value);
        }
    },[value])
    
  return (
    <div className="app-sliders">
        {myVal >= 0 ?
            <Slider value={myVal} />
            :
            <Slider min={-100} max={0} value={myVal} marks={marks} />
        }
      
    </div>
  );
}

export default ProgressLinear;
