import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";
import BusinessAppLayout from "../../../layout/components/BusinessAppLayout";
import businessEventServices from "../businessEventServices";

import MapInputComponent from "../components/MapInputComponent";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl/FormikControl";

import ImageInput from "../../../components/ImageInput/ImageInput";
import { Dialog, DialogContent, DialogTitle, DialogActions, LinearProgress } from "@mui/material";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router";
import { useAlert } from "../../../context/AlertContext";
import businessProfileServces from "../businessProfileServces";
import BusinessAdminLayout from "../layout/BusinessAdminLayout";
import ProgressCircle from "../components/ProgressCircle";
import ProgressLinear from "../components/ProgressLinear";
import GooglePlaceComponent from "../components/GooglePlaceComponent";
import CahngePassword from "../components/CahngePassword";
import YourPlanPage from "../components/YourPlanPage";

function BusinessProfileFormPage() {
  const { businessFeedData, getBusinessFeedData } = useBusinessFeedData();
  const business_profile = businessFeedData.business_profile;
  const { authUser } = useAuth();
  const { AppAlert } = useAlert();
  const navigate = useNavigate();
  const [eventTypes, setEventTypes] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [latLng, setLatLng] = useState(null);
  const [address, setAddress] = useState(null);
  const [saving, setSavingStatus] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const [dialogStatus, setDialogStatus] = useState(false);

  const getEventTypes = () => {
    businessEventServices
      .getEventTypes()
      .then((res) => {
        setEventTypes(res.data.event_types);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getEventTypes();
  }, []);

  const initialValues = {
    name: "",
    phone: "",
    user_token: authUser.user_token,
  };

  const validationRule = Yup.object({
    business_name: Yup.string().required("Required"),
    phone_no: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    if (address === "") {
      AppAlert("Event Address Required", "error");
      return false;
    } else {
      values.address = address;
    }
    if (imageBase64 !== null) {
      values.event_image_base64 = imageBase64;
    }
    if (latLng !== null) {
      values.latitude = latLng.lat;
      values.longitude = latLng.lng;
    }
    console.log(values);

    businessProfileServces
      .updateProfile(values)
      .then((res) => {
        console.log("res->", res);
        if (res.status == "success") {
          getBusinessFeedData();
          console.log("edit successfully");
          AppAlert(res.data.message);
          setTimeout(() => {
            //navigate("/business/profile");
          }, [2000]);

          //business/events/details/680
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Edit values
  useEffect(() => {
    if (businessFeedData) {
      let autoFill = {
        business_name: businessFeedData.business_profile.name,
        mood: businessFeedData.business_profile.mood_id,
        email: businessFeedData.business_profile.email,
        phone_no: businessFeedData.business_profile.phone,
        mobile_country: businessFeedData.business_profile.mobile_country,
        website: businessFeedData.business_profile.website,
        user_token: authUser.user_token,
      };
      setFormValues(autoFill);
      setAddress(businessFeedData.business_profile.address);
      const _latLng = {
        lat: businessFeedData.business_profile.latitude,
        lng: businessFeedData.business_profile.longitude,
      };
      setLatLng(_latLng);
    }
  }, [businessFeedData]);


    const removeBusinesaAccount = () =>{
        setSavingStatus(true);
            let payload = {user_token: authUser.user_token}

            
            businessProfileServces.removeAccount(payload).then(res => {
                console.log(res);
                AppAlert(res.data.message);
                navigate("/auth/logout")
            }).catch(error=>{
                console.log(error)
            }).finally(()=>setSavingStatus(false))
            


            
    }

  return (
    <BusinessAdminLayout>
      <div className="top-heading-row">
        <h1 class="web-page-heading">Settings</h1>
      </div>
      <div className="row">
        <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-12">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationRule}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnMount
          >
            {(formik) => {
              //console.log(formik);
              return (
                <Form>
                  <div className="card">
                    <div className="card-text-title">
                      <h5 className="card-title">Business Settings</h5>
                    </div>
                    <div className="card-body">
                      <div className="left-profile-card">
                        <div className="row row-cols-1 g-2">
                          <div className="col">
                            <div
                              className="d-inline-block"
                              style={{ border: "2px solid #F3F1F1" }}
                            >
                              <div className="sign-up-profile ms-0 mb-2 mt-0">
                                <ImageInput
                                  label={"Profile Image"}
                                  value={
                                    businessFeedData
                                      ? businessFeedData.business_profile.image
                                      : null
                                  }
                                  setImageBase64={setImageBase64}
                                  type="circle"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <FormikControl
                              control="bsg-input"
                              formik={formik}
                              type="text"
                              placeholder="Business Name"
                              label={<i className="bx bx-user-circle icon"></i>}
                              name="business_name"
                            />
                          </div>
                          {eventTypes && (
                            <div className="col">
                              {/* <div className="float-start me-2"><MoodRounded fontSize="small" /></div> */}
                              <FormikControl
                                control="bsg-select"
                                formik={formik}
                                options={eventTypes}
                                valueField="event_type_id"
                                labelField="type_name"
                                name="mood"
                                label={
                                  <img
                                    src="/assets/img/icons/mood.svg"
                                    alt="event-mood"
                                  />
                                }
                              />
                            </div>
                          )}
                          <div className="col">
                            <FormikControl
                              control="bsg-input"
                              formik={formik}
                              type="text"
                              placeholder="Email"
                              label={<i class="bx bx-envelope icon"></i>}
                              name="email"
                            />
                          </div>
                          <div className="col">
                            {/*
                            <FormikControl
                              control="bsg-input"
                              formik={formik}
                              type="text"
                              placeholder="Address"
                              label={<i className="bx bx-user-circle icon"></i>}
                              name="address"
                            />
                            */}
                            {address !== null && (
                              <GooglePlaceComponent
                                address={address}
                                setAddress={setAddress}
                                latLng={latLng}
                                setLatLng={setLatLng}
                              />
                            )}
                          </div>
                          <div className="col">
                            <FormikControl
                              control="bsg-input"
                              formik={formik}
                              type="text"
                              placeholder="Phone"
                              // label={<i className="bx bx-user-circle icon"></i>}
                              label={
                                <img
                                  src="/assets/img/icons/call.svg"
                                  alt="event-mood"
                                />
                              }
                              name="phone_no"
                            />
                          </div>
                          <div className="col">
                            <FormikControl
                              control="bsg-input"
                              formik={formik}
                              type="text"
                              placeholder="Website"
                              label={<i class="bx bx-globe icon"></i>}
                              name="website"
                            />
                          </div>
                          {/* <div className="col">
                            {businessFeedData && (
                              <MapInputComponent
                                lat={businessFeedData.business_profile.latitude}
                                lng={
                                  businessFeedData.business_profile.longitude
                                }
                                mapHeight={"300px"}
                                markerId={"ahmedabad"}
                                setLatLng={setLatLng}
                              />
                            )}
                          </div> */}
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="form-group">
                          {saving ? (
                            <LinearProgress />
                          ) : (
                            <button
                              className="btn btn-primary app-btn text-capitalize app-btn-fade fw-light"
                              type="submit"
                            >
                              save
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body py-0 bg-white">
                      <div className="mt-3">
                        {/*
                        <button
                          type="button"
                          className="btn btn-secondary app-btn text-capitalize fw-light"
                        >
                          Change Password old
                        </button>
                        */}
                        <CahngePassword />
                      </div>
                      <div className="mt-3">
                        <button
                          type="button"
                          className="btn btn-outline-danger border-2 app-btn text-capitalize fw-light"
                          onClick={()=>setDialogStatus(true)}
                        >
                          Delete account
                        </button>
                        <Dialog
                            open={dialogStatus}
                            keepMounted
                            onClose={() => setDialogStatus(false)}
                            maxWidth="sm"
                        >
                            <DialogTitle>Confirm Delete</DialogTitle>
                            <DialogContent>
                                <p className="mb-0">This action cannot be undone. Are you sure you want to
                                delete your account?</p>
                            </DialogContent>
                            {saving ?
                                <LinearProgress/>
                                :
                                <DialogActions>
                                    
                                    <button className="btn btn-outline-danger border-2 app-btn text-capitalize" onClick={removeBusinesaAccount}  >Delete</button>
                                    <button className="app-btn text-capitalize app-btn-fade border-0" onClick={() => setDialogStatus(false)} >Cancel</button>
                                </DialogActions>
                            }
                            

                        </Dialog>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <YourPlanPage/>
      </div>
      {/* JSON.stringify(businessFeedData.business_profile) */}
    </BusinessAdminLayout>
  );
}

export default BusinessProfileFormPage;
