import api from "../../api/api";

const submitQuery = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("create_support_ticket",payload).then( (res)=>{
            if(res.data.status === "success"){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.data.message
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const businessSupportServices = {
    submitQuery,
}

export default businessSupportServices;
