import React from "react";
import ContactSupportForm from "../components/ContactSupportForm";
import BusinessAdminLayout from "../layout/BusinessAdminLayout";

function BusinessSupportPage() {
  return (
    <BusinessAdminLayout>
      <div className="top-heading-row">
        <h1 class="web-page-heading">support</h1>
      </div>
      <div className="row">
        <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-12">
          <div className="youtube-videos">
            <div className="card">
              <div class="card-text-title">
                <h5 class="card-title">Tutorials</h5>
              </div>
              <div className="card-body">
                <div className="row row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-6 row-cols-1 g-3">
                  <div className="col">
                    <div className="youtube-card">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/K4TOrB7at0Y?si=GK3xMiSwFAvnrzTa"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                      ></iframe>
                      <div className="video-detail">
                        <ul>
                          <li>
                            <i class="bx bxs-dashboard"></i>
                          </li>
                          <li>
                            <h5>How To Use The Dashboard</h5>
                            <p>1min Watch</p>
                          </li>
                        </ul>
                        {/* <li>
                          <button type="button" className="btn btn-light">
                            <i class="bx bx-play-circle"></i>
                          </button>
                        </li> */}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="youtube-card">
                       <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/K4TOrB7at0Y?si=GK3xMiSwFAvnrzTa"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                      ></iframe>
                      <div className="video-detail">
                        <ul>
                          <li>
                            <i class="bx bxs-dashboard"></i>
                          </li>
                          <li>
                            <h5>How To Use The Dashboard</h5>
                            <p>1min Watch</p>
                          </li>
                        </ul>
                        {/* <li>
                          <button type="button" className="btn btn-light">
                            <i class="bx bx-play-circle"></i>
                          </button>
                        </li> */}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="youtube-card">
                       <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/K4TOrB7at0Y?si=GK3xMiSwFAvnrzTa"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                      ></iframe>
                      <div className="video-detail">
                        <ul>
                          <li>
                            <i class="bx bxs-dashboard"></i>
                          </li>
                          <li>
                            <h5>How To Use The Dashboard</h5>
                            <p>1min Watch</p>
                          </li>
                        </ul>
                        {/* <li>
                          <button type="button" className="btn btn-light">
                            <i class="bx bx-play-circle"></i>
                          </button>
                        </li> */}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="youtube-card">
                       <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/K4TOrB7at0Y?si=GK3xMiSwFAvnrzTa"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                      ></iframe>
                      <div className="video-detail">
                        <ul>
                          <li>
                            <i class="bx bxs-dashboard"></i>
                          </li>
                          <li>
                            <h5>How To Use The Dashboard</h5>
                            <p>1min Watch</p>
                          </li>
                        </ul>
                        {/* <li>
                          <button type="button" className="btn btn-light">
                            <i class="bx bx-play-circle"></i>
                          </button>
                        </li> */}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="youtube-card">
                       <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/K4TOrB7at0Y?si=GK3xMiSwFAvnrzTa"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                      ></iframe>
                      <div className="video-detail">
                        <ul>
                          <li>
                            <i class="bx bxs-dashboard"></i>
                          </li>
                          <li>
                            <h5>How To Use The Dashboard</h5>
                            <p>1min Watch</p>
                          </li>
                        </ul>
                        {/* <li>
                          <button type="button" className="btn btn-light">
                            <i class="bx bx-play-circle"></i>
                          </button>
                        </li> */}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="youtube-card">
                       <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/K4TOrB7at0Y?si=GK3xMiSwFAvnrzTa"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                      ></iframe>
                      <div className="video-detail">
                        <ul>
                          <li>
                            <i class="bx bxs-dashboard"></i>
                          </li>
                          <li>
                            <h5>How To Use The Dashboard</h5>
                            <p>1min Watch</p>
                          </li>
                        </ul>
                        {/* <li>
                          <button type="button" className="btn btn-light">
                            <i class="bx bx-play-circle"></i>
                          </button>
                        </li> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-12">
          <div className="card">
            <div class="card-text-title">
              <h5 class="card-title">Contact Support</h5>
            </div>
            <div className="card-body">
              <ContactSupportForm />
            </div>
          </div>
        </div>
      </div>
    </BusinessAdminLayout>
  );
}

export default BusinessSupportPage;
