import React from 'react'
import { Link } from 'react-router-dom'

function UserDashboardPage() {
  return (
      <div className="container">
          <div className="row mt-5">
              <div className="col-md-6 offset-md-3 text-center">
                <h3>User Dashboard Not Defined</h3>
                <Link to={"/auth/logout"} className="btn btn-primary" >Logout</Link>
              </div>
          </div>
      </div>
    
  )
}

export default UserDashboardPage