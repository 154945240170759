import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router';

function DashboardOngoingEvents({eventList}) {
    const navigate = useNavigate()
    const handleCreateEvent = () =>{
        navigate("/business/events/form");
    }
  return (
    <div className="col">
          <div className="card">
            <div class="card-text-title">
              <h5 class="card-title">Ongoing Events</h5>
            </div>
            <div className="card-body">
              <div className="dashboard-oe">
                {eventList?.map(eventDetails=>
                    <ul key={eventDetails.event_id} class="upcoming-events-data bg-white p-3 rounded-5 mb-2">
                    <li class="event-right-date-up">
                        <h5 class="card-subtitle">
                        <i class="bx bx-user-circle"></i> Going
                        </h5>
                        <h3 class="card-title"> {eventDetails.statistics.going.numberOfAttendees}</h3>
                        <p class="card-text" style={{ overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis", width:130, }}>
                        {eventDetails.event_title} <br />
                        <span>{moment(eventDetails.start_date).format("DD/MM/YY")}</span>
                        
                        </p>
                        <Link to={"/business/events/form/"+eventDetails.event_id} class="btn app-btn-fade app-btn">SEE EVENT</Link>
                    </li>
                    <li class="event-left-date-up">
                        <img
                        src={eventDetails.image}
                        alt="event img"
                        class="img-fluid"
                        />
                    </li>
                    </ul>
                )}
                
                            
                <div onClick={handleCreateEvent} style={{cursor:"pointer"}}>
                    <div class="no-events-cards">
                        <h1 class="card-title">CREATE ANOTHER <span>EVENT</span></h1>
                        <p class="card-text">No Event</p>
                        <div class="date-time-event">
                            <p>Date</p>
                            <h1>00<span>/</span>00</h1>
                            <div class="position-absolute top-100 start-50 translate-middle">
                                <img src="/assets/img/add-event-icon.svg" alt="add-event-icon" />
                            </div>
                        </div>
                    </div>
                </div>
                        
                {/*<ul class="upcoming-events-data bg-white p-3 rounded-5 mb-2">
                  <li class="event-right-date-up">
                    <h5 class="card-subtitle">
                      <i class="bx bx-user-circle"></i> Going
                    </h5>
                    <h3 class="card-title">887</h3>
                    <p class="card-text">
                      Barge Festival <br />
                      <span>28/02/24</span>
                    </p>
                    <button class="btn app-btn-fade app-btn">SEE EVENT</button>
                  </li>
                  <li class="event-left-date-up">
                    <img
                      src="https://emle.org/wp-content/uploads/revslider/news/demo-img-2-3.png"
                      alt="event img"
                      class="img-fluid"
                    />
                  </li>
                </ul>
                */}
              </div>
            </div>
          </div>
        </div>
  )
}

export default DashboardOngoingEvents