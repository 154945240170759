import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router';
import { CalendarMonthRounded, Edit, PinDrop } from '@mui/icons-material'
import moment from 'moment'
import { useBusinessFeedData } from '../../../context/BusinessFeedContext';
import MapComponent from '../components/MapComponent';
import { Link } from 'react-router-dom';
import BusinessAdminLayout from '../layout/BusinessAdminLayout';

function BusinessEventDetailsPage() {
    let {businessFeedData} = useBusinessFeedData();
    const {event_id} = useParams()

    const [eventDetails,setEventDetails] = useState(null)
    const getEventDetails = () =>{
        if(businessFeedData!==null){
            let _eventDetails = businessFeedData.event_list.ongoing.find(_event => _event.event_id == event_id)
            if(_eventDetails == null){
                console.log("here");
                _eventDetails = businessFeedData.event_list.upcomming.find(_event => _event.event_id == event_id)
            }
            setEventDetails(_eventDetails)
        }
    }


    useEffect(()=>{
        getEventDetails();
    },[businessFeedData, event_id])
    return (
        <BusinessAdminLayout>
            {eventDetails &&
                <div className="container">
                    <h1 class="web-page-heading">{eventDetails.event_title}</h1>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <Link to={"/business/events/form/"+event_id} className="btn float-end"><Edit/></Link>
                                </div>
                                <div className="col-md-12"><hr/></div>
                            </div>
                                

                            <div className="card">
                                <img src={eventDetails.image} className="event-thumb-image rounded" />
                            </div>
                            <div className="my-4">
                                <h4 className="my-2 fw-bold">{eventDetails.event_title}</h4>
                            </div>
                            <div className="d-flex justify-content-start">
                                <div className="d-flex justify-content-start">
                                    <CalendarMonthRounded sx={{fontSize:25}}/>
                                    <div className="ms-2">
                                        <div className="event-date">{moment(eventDetails.start_date).format("M MMMM, YYYY")}</div>
                                        <div className="event-time">{moment(eventDetails.start_date).format("dddd, h:mmA")}</div>
                                    </div>
                                </div>

                                <div className=" ms-3 d-flex justify-content-start">
                                    <PinDrop sx={{fontSize:25}} />
                                    <div className="ms-2">
                                        <div className="event-date">{eventDetails.business_name}</div>
                                        <div className="event-location">{eventDetails.address}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-4">
                                <h6 className="my-2 fw-bold">About Event</h6>
                            </div>
                            <div className="my-4">
                                <p className="my-2">{eventDetails.event_description}</p>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <MapComponent
                                        //lat="23.12968584459772"
                                        //lng="72.5411098985123"
                                        lat={eventDetails.latitude}
                                        lng={eventDetails.longitude}
                                        mapHeight={"300px"}
                                        markerId={"ahmedabad"}
                                    />
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-12">
                            {/*JSON.stringify(eventDetails)*/}
                        </div>
                    </div>
                </div>
            }
        </BusinessAdminLayout>
    )
}

export default BusinessEventDetailsPage