import React, { useEffect, useState } from "react";
import { useAlert } from "../../../context/AlertContext";
import { useAuth } from "../../../context/AuthContext";
import businessTaskServices from "../businessTaskServices";
import TaskForm from "./TaskForm";

function TaskManager() {
  const authUser = useAuth();
  const { AppAlert } = useAlert();
  const [taskList, setTaskList] = useState(null);
  const [dialogStatus, setDialogStatus] = useState(false);
  const getTaskList = () => {
    businessTaskServices
      .getTaskList({
        business_id: authUser.authUser.id,
        user_token: authUser.authUser.user_token,
      })
      .then((res) => {
        setTaskList(res.data.task_list);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  useEffect(() => {
    getTaskList();
  }, []);

  const save = (val, resetForm) => {
    val.user_token = authUser.authUser.user_token;
    businessTaskServices
      .saveTask(val)
      .then((res) => {
        AppAlert(res.data.message);
        setDialogStatus(false);
        resetForm();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        getTaskList();
      });
  };

  const removeTask = (task_id) => {
    let payload = {
      task_id: task_id,
      user_token: authUser.authUser.user_token,
    };
    console.log("delete task", payload);
    businessTaskServices
      .removeTask(payload)
      .then((res) => {
        AppAlert(res.data.message);
        setDialogStatus(false);
      })
      .catch((error) => {
        console.log("error is", error);
        AppAlert(error.message, "error");
      })
      .finally(() => {
        getTaskList();
      });
  };

  const updateStatus = (val) => {
    val.status = val.status == 0 ? 1 : 0;
    save(val);
  };
  const updateFavStatus = (val) => {
    val.is_favourite = val.is_favourite == 0 ? 1 : 0;
    save(val);
  };

  const [selectedTask, setSelectedTask] = useState(null);
  const updateTask = (task) => {
    setSelectedTask(task);
    setDialogStatus(true);
  };
  const createTask = () => {
    setSelectedTask(null);
    setDialogStatus(true);
  };

  return (
    <div className="card">
      <div class="card-text-title">
        <h5 class="card-title">My Tasks</h5>
      </div>
      <div className="card-body">
        <div className="my-tasks">
          <ul className="list-group list-group-flush">
            {taskList &&
              taskList.map((task) => (
                <li className="list-group-item" key={task.task_id}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={task.status}
                      onClick={() => updateStatus(task)}
                    />
                    <label
                      className="form-check-label"
                      onClick={() => updateTask(task)}
                    >
                      {task.task_title}
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-sm p-0 shadow-none border-0"
                    onClick={() => updateFavStatus(task)}
                  >
                    <i
                      className={
                        task.is_favourite == 0
                          ? "bx bx-star"
                          : "bx bxs-star text-warning"
                      }
                    ></i>
                  </button>
                </li>
              ))}
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <button
                className="btn app-btn-fade rounded-pill"
                onClick={createTask}
              >
                Add New
              </button>
            </li>
          </ul>

          <TaskForm
            dialogStatus={dialogStatus}
            setDialogStatus={setDialogStatus}
            onSuccess={getTaskList}
            selectedTask={selectedTask}
            save={save}
            removeTask={removeTask}
          />
        </div>
      </div>
    </div>
  );
}

export default TaskManager;

/*

    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                        <input
                            className="form-check-input me-3"
                            type="checkbox"
                            defaultValue
                            id="firstCheckboxStretched"
                        />
                        <label
                            className="form-check-label stretched-link"
                            htmlFor="firstCheckboxStretched"
                        >
                            First checkbox
                        </label>
                        </div>
                        <span className="text-warning">
                        <i class="bx bxs-star"></i>
                        </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                        <input
                            className="form-check-input me-3"
                            type="checkbox"
                            defaultValue
                            id="firstCheckboxStretched"
                        />
                        <label
                            className="form-check-label stretched-link"
                            htmlFor="firstCheckboxStretched"
                        >
                            First checkbox
                        </label>
                        </div>
                        <span className="text-warning">
                        <i class="bx bxs-star"></i>
                        </span>
                    </li>

*/
