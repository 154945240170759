import React,{useState,useEffect} from 'react'
import BusinessAppLayout from "../../../layout/components/BusinessAppLayout";
import CircularProgress from "@mui/joy/CircularProgress";
import { Height, Percent } from "@mui/icons-material";
import ProgressCircle from "./ProgressCircle";
import ProgressLinear from "./ProgressLinear";
import { useAuth } from '../../../context/AuthContext';
import businessEventServices from '../businessEventServices';

function StatisticsComponent({eventDetails}) {

    const authUser = useAuth();
    
    const [stats,setStats] = useState(null);


    const [genderVal,setGenderVal] = useState("...");
    


    const getStats = () =>{
        /*
        let payload = {
            event_id : eventDetails.event_id,
            user_token : authUser.authUser.user_token
        }
        businessEventServices.getEventStats(payload).then(res=>{
            setStats(res.data.statistics)
            console.log(res.data);
        }).catch(error=>{

        }).finally(()=>{

        })
        */
        setStats(eventDetails.statistics)
        setGenderVal(eventDetails.statistics?.gender.percentage);
    }

    useEffect(()=>{
        getStats();
    },[eventDetails])

    return (
        <div className="card mb-2">
            <div class="card-text-title">
                <h5 class="card-title">{eventDetails.event_title}</h5>
            </div>
            {/*
            <div className="card-body">
                {JSON.stringify(stats)}
            </div>
            */}
            {stats &&
            <div className="card-body">
                <div className="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-3">
                    <div className="col d-flex">
                        <div className="dashboard-cards">
                            <ul>
                                <li>
                                    <ProgressCircle value={stats?.going?.percentage} />
                                </li>
                                <li className="text-end">
                                    <h4>
                                    <i class="bx bx-user-circle"></i>
                                    Going
                                    </h4>
                                    <h1>{stats?.going?.numberOfAttendees}</h1>
                                    <p>{stats?.going?.percentageForEvent}% {stats.going.percentageForEvent >= 0?  "Up" : "Down"} This Event</p>
                                </li>
                            </ul>
                            <ProgressLinear value={stats?.going?.percentageForEvent} />
                        </div>
                    </div>
                    <div className="col d-flex">
                        <div className="dashboard-cards">
                            <ul>
                            <li>
                                <ProgressCircle value={stats?.coverage.percentage} />
                            </li>
                            <li className="text-end">
                                <h4>
                                <img src="./assets/img/icons/users.svg" alt="" />
                                Coverage
                                </h4>
                                <h1>{stats?.coverage?.numberOfAttendees}</h1>
                                <p>{stats?.coverage?.percentageForEvent}% {stats.coverage.percentageForEvent >= 0?  "Up" : "Down"} This Event</p>
                            </li>
                            </ul>
                            <ProgressLinear value={stats?.coverage.percentageForEvent} />
                        </div>
                    </div>
                    <div className="col d-flex">
                        <div className="dashboard-cards">
                            <ul>
                            <li>
                                <ProgressCircle value={stats?.subscribed.percentage} />
                            </li>
                            <li className="text-end">
                                <p style={{ color: "#D4D4D4" }}>
                                How many people have subscribed to your business
                                </p>
                                <h4>Subscribed</h4>
                                <h1>{stats?.subscribed?.numberOfSubscribers}</h1>
                                <p>{stats?.subscribed?.percentageForEvent}% {stats.subscribed.percentageForEvent >= 0?  "Up" : "Down"} This Event</p>
                            </li>
                            </ul>
                            <ProgressLinear value={stats?.subscribed.percentageForEvent} />
                        </div>
                    </div>
                    <div className="col d-flex">
                        <img
                        style={{ height: '176px' }}
                            src={eventDetails.image}
                            alt="event img"
                            class="img-fluid rounded-5 w-100"
                        />
                        </div>
                        <div className="col d-flex">
                        <div className="statistics-card">
                            <ul>
                            <li className="mb-3">
                                <h5>Age</h5>
                                <h2>AVE: {stats?.age.average}</h2>
                            </li>
                            <li>
                            {stats?.age?.attendees?.map(attendee=>
                                <div class="date-time-event">
                                    <p>{attendee.title}</p>
                                    <h1>{attendee.value}</h1>
                                </div>
                            )}
                            </li>
                            {/*
                            <li>
                                <div class="date-time-event">
                                    <p>18-26</p>
                                    <h1>01</h1>
                                </div>
                                <div class="date-time-event">
                                    <p>18-26</p>
                                    <h1>01</h1>
                                </div>
                                <div class="date-time-event">
                                    <p>18-26</p>
                                    <h1>01</h1>
                                </div>
                            </li>
                            */}
                            </ul>
                        </div>
                    </div>
                    <div className="col d-flex">
                        <div className="statistics-card">
                            <ul>
                            <li className="mb-3">
                                <h5>Sex</h5>
                                <h2>{genderVal}<span>%</span></h2>
                            </li>
                            <li>
                            {stats?.gender?.attendees?.map(attendee=>
                                <div class="date-time-event">
                                    <p>{attendee.title}</p>
                                    <h1 role="button" onClick={()=>setGenderVal(attendee.percentage)} >{attendee.value}</h1>
                                </div>
                            )}
                            </li>
                            {/*
                            <li>
                                <div class="date-time-event">
                                <p>18-26</p>
                                <h1>01</h1>
                                </div>
                                <div class="date-time-event">
                                <p>18-26</p>
                                <h1>01</h1>
                                </div>
                                <div class="date-time-event">
                                <p>18-26</p>
                                <h1>01</h1>
                                </div>
                            </li>
                            */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default StatisticsComponent