import { CircularProgress } from '@mui/material';
import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router';
import { useAlert } from '../../../context/AlertContext';
import { useAuth } from '../../../context/AuthContext';
import { useBusinessFeedData } from '../../../context/BusinessFeedContext';
import AuthDevieDisplay from '../../auth/components/AuthDevieDisplay'
import AuthHeader from '../../auth/components/AuthHeader'
import stripeServices from '../stripeServices';



function BusinessStripeStatusPage() {

    const [loading,setLoadingStatus] = useState(false);
    const [paymentStatus,setPaymentStatus] = useState(null);
    const navigate = useNavigate();
    const {businessFeedData,getBusinessFeedData}  = useBusinessFeedData();
    const { authUser } = useAuth();
    const { AppAlert } = useAlert();

    const getPaymentStatus = () =>{
        setLoadingStatus(true)
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        stripeServices.checkoutStatus({packageId:4,user_token:authUser.user_token,sessionId:sessionId}).then(res=>{
            //console.log(res);
            setPaymentStatus(res);
            AppAlert(res.data.message);
            getBusinessFeedData();
        }).catch(error=>{
            console.log(error)
        }).finally(()=>{
            setLoadingStatus(false)
        })
    }

    useEffect(()=>{
        getPaymentStatus();
    },[])

    
    useEffect(()=>{
        console.log(businessFeedData.business_profile)
        if(businessFeedData?.business_profile.is_subscribed == 1){
            navigate("/")
        }
    },[businessFeedData])

    return (
        <section class={"login-areas sign-up-consumer pt-5"}>
            <div class="container">
                <div class="login-box">
                    <div class="row g-3">
                        <div class="col-lg-4 d-flex offset-xl-1">
                            <div class="w-100">
                                <AuthHeader/>
                                <div className="card">
                                    <div className="card-body px-4">
                                        {loading &&
                                            <div class="text-center">
                                                <CircularProgress/>
                                            </div>
                                        }
                                        {!loading && paymentStatus &&
                                            <div class="text-center">
                                                <h3>Payment Status : {paymentStatus.status}</h3>
                                            </div>
                                        }
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AuthDevieDisplay />
                    </div>
                </div>
            </div>
        </section>
        )
}

export default BusinessStripeStatusPage