import React,{useState,useRef} from 'react'
import GoogleMap from 'google-maps-react-markers'

const Marker = ({lat,lng,markerId}) => <div className="dragable-marker"></div>

function MapComponent({lat,lng,markerId,mapHeight}) {
    const mapRef = useRef(null)
    const [mapReady, setMapReady] = useState(false)
    return (
        <div style={{height:mapHeight}}>
            <GoogleMap
                apiKey=""
                defaultCenter={{ lat: parseFloat(lat), lng:  parseFloat(lng) }}
                defaultZoom={15}
            >
                <Marker
                    lat={lat}
                    lng={lng}
                    markerId={"ahmedabad"}
                />
            </GoogleMap>
        </div>
    )
    
}

export default MapComponent