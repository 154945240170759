import React,{useState} from 'react'
import Slider from "@mui/material/Slider";
import {Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";


function RangeSelector({rangeValue,setRangeValue}) {
    const [dialogStatus, setDialogStatus] = useState(false);
    const handleRangeChange = (event, newValue) => {
        setRangeValue(newValue);
    };

    const handleRangeOpen = () =>{
        if(rangeValue[0]==0 && rangeValue[1] == 0){
            setRangeValue([0,65])
        }
        setDialogStatus(true)
    }
    function valuetext(value) {
        return `${value} Years`;
    }
    return (
                        <div className="form-group">
                          <i class="bx bx-user-circle icon"></i>
                          <div
                            className="planet-label form-control"
                            onClick={handleRangeOpen}
                          >
                            {rangeValue[0] == 0 && rangeValue[1] == 0 && <span className="">Age Range</span>}
                            {rangeValue[1] > 0 && <span className="text-dark"> {rangeValue[0]} yrs - {rangeValue[1] < 65 ? rangeValue[1] : rangeValue[1]+"+"} yrs</span>}
                          </div>
                          <Dialog
                            open={dialogStatus}
                            keepMounted
                            onClose={() => setDialogStatus(false)}
                            maxWidth="sm"
                          >
                            <DialogTitle>Select Age Range</DialogTitle>
                            <DialogContent>
                              <h3>
                                {rangeValue[0]} Years - {rangeValue[1] < 65 ? rangeValue[1] : rangeValue[1]+"+" } Years
                              </h3>
                              <Slider
                                value={rangeValue}
                                size="small"
                                min={0}
                                max={65}
                                onChange={handleRangeChange}
                                sx={{ color: "#111" }}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                              />
                            </DialogContent>
                            <DialogActions>
                              <button
                                className={"btn btn-primary"}
                                onClick={() => setDialogStatus(false)}
                              >
                                {" "}
                                Accept{" "}
                              </button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      
  )
}

export default RangeSelector