import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import subscriptionServices from "../subscriptionServices";
import ProgressCircle from "./ProgressCircle";
import ProgressLinear from "./ProgressLinear";

function YourPlanPage() {
  const { authUser } = useAuth();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [percentage, setPercentage] = useState("0");
  const getSubscriptionDetails = () => {
    subscriptionServices
      .getSubscriptionDetails(authUser.user_token)
      .then((res) => {
        setSubscriptionDetails(res.data.subscription_details);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculatePercentage = (used, total) => {
    return parseInt((100 * used) / total);
  };

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  return (
    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-12">
      <div className="card">
        <div className="card-body">
          <div className="row row-cols-1 g-4">
            <div className="col">
              <div className="setting-plan">
                <ul>
                  <li>
                    <h5>Your Plan</h5>
                    <h2>{subscriptionDetails?.package_name}</h2>
                    <p>
                      {subscriptionDetails?.days_to_renewal} Days To Renewal
                    </p>
                  </li>
                  <li>
                    <img
                      src={
                        "/images/" +
                        subscriptionDetails?.package_name +
                        "_Medal.svg"
                      }
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="setting-progress">
                <ul>
                  <li>
                    <ProgressCircle
                      value={calculatePercentage(
                        subscriptionDetails?.total_published_events,
                        subscriptionDetails?.total_published_events +
                          subscriptionDetails?.total_remaining_events
                      )}
                      showPercentage={false}
                      displayValue={subscriptionDetails?.total_published_events}
                    />
                  </li>
                  <li>
                    <h1>Posts Used</h1>
                    <p>
                      {subscriptionDetails?.total_published_events}/
                      {subscriptionDetails?.total_published_events +
                        subscriptionDetails?.total_remaining_events}{" "}
                      Event Posts Used
                    </p>
                  </li>
                </ul>
                <ProgressLinear
                  value={calculatePercentage(
                    subscriptionDetails?.total_published_events,
                    subscriptionDetails?.total_published_events +
                      subscriptionDetails?.total_remaining_events
                  )}
                />
              </div>
            </div>
            <div className="col">
              <div className="setting-progress">
                <ul>
                  <li>
                    <ProgressCircle
                      value={calculatePercentage(
                        subscriptionDetails?.total_remaining_events,
                        subscriptionDetails?.total_published_events +
                          subscriptionDetails?.total_remaining_events
                      )}
                      showPercentage={false}
                      displayValue={subscriptionDetails?.total_remaining_events}
                    />
                  </li>
                  <li>
                    <h1>Remaining</h1>
                    <p>
                      {subscriptionDetails?.total_remaining_events} Posts
                      Remaining
                    </p>
                  </li>
                </ul>
                <ProgressLinear
                  value={calculatePercentage(
                    subscriptionDetails?.total_remaining_events,
                    subscriptionDetails?.total_published_events +
                      subscriptionDetails?.total_remaining_events
                  )}
                />
              </div>
            </div>
            <div className="col">
              <div className="setting-progress">
                <ul>
                  <li>
                    <ProgressCircle
                      value={calculatePercentage(
                        subscriptionDetails?.days_to_renewal,
                        30
                      )}
                      showPercentage={false}
                      displayValue={subscriptionDetails?.days_to_renewal}
                    />
                  </li>
                  <li>
                    <h1>Renewal</h1>
                    <p>
                      {subscriptionDetails?.days_to_renewal} Days to Renewal
                    </p>
                  </li>
                </ul>
                <ProgressLinear
                  value={calculatePercentage(
                    30 - subscriptionDetails?.days_to_renewal,
                    30
                  )}
                />
              </div>
            </div>
            <div className="col">
              <Link
                to={"/business/profile/plan"}
                className="btn btn-primary app-btn app-btn-fade"
              >
                Upgrade Your Plan Now!
              </Link>
            </div>
            <div className="col">
              <button type="button" className="btn bg-white app-btn">
                My Billing Information!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YourPlanPage;
