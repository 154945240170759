import React from 'react'
import ProgressCircle from "../components/ProgressCircle";
import ProgressLinear from "../components/ProgressLinear";
import { useNavigate } from "react-router";

function UpcomingPlaceholder() {
    const navigate = useNavigate();
    const handleCreateEvent = () => {
        navigate("/business/events/form");
    };
    return (
    
        <div
            class="card"
            onClick={handleCreateEvent}
            style={{ cursor: "pointer" }}
        >
            <div class="card-text-title">
              <h5 class="card-title">Your Upcoming Event statistics : No Event</h5>
            </div>
            <div class="card-body">
              <div class="upcoming-events-row">
                <div class="row g-4">
                  <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 d-flex">
                    <ul class="upcoming-events-data dull-event">
                      <li class="event-left-date-up">
                        <div class="progress-circle-container mb-3">
                          <ProgressCircle value={0} color="grey" />
                        </div>
                        <ProgressLinear value={0} />
                        <p>No Event</p>
                      </li>
                      <li class="event-right-date-up">
                        <h5 class="card-subtitle">
                          <i class="bx bx-user-circle"></i> Going
                        </h5>
                        <h3 class="card-title">0</h3>
                        <p class="card-text">
                          No Event
                          <br />
                          <span>No Event</span>
                        </p>
                        <button class="btn app-btn-fade app-btn px-5" disabled>
                          edit
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="col-xxl-7 col-xl-8 col-lg-6 col-md-12 d-flex offset-xl-1">
                    <div class="no-events-cards w-100 d-flex justify-content-center align-items-center flex-column">
                      <h1 class="card-title">
                        CREATE ANOTHER <span>EVENT</span>
                      </h1>
                      <img
                        src="/assets/img/add-event-icon.svg"
                        alt="add-event-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
  )
}

export default UpcomingPlaceholder