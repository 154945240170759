import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import stripeServices from "../stripeServices";
import { useParams } from "react-router";
import { useAuth } from "../../../context/AuthContext";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";

const stripePromise = loadStripe(
  "pk_live_51PVmiz2MFykeC0YXhHrR1TA1OYgaDXizWlCDX6hcNd5r0EigThsISfjSZ2KaKY6z9bQRv3AKCJmmefTU7NKDZFNe00PWzis3hg"
);

function StripeCheckoutComponent() {
  /*
    const options = {
        // passing the client secret obtained from the server
        clientSecret: 'cs_test_a1apf6FIU6WElchJw5VOuqm7vQVmUJRcY5H0XNFH7lz9JFJtks4TMXxDmt_secret_fid2cGd2ZndsdXFsamtQa2x0cGBrYHZ2QGtkZ2lgYSc%2FY2RpdmApJ3BsSGphYCc%2FJ2BoZ2BhYWBhJyknaWR8anBxUXx1YCc%2FJ3Zsa2JpYFpscWBoJyknd2BhbHdgZnFKa0ZqaHVpYHFsamsnPydkaXJkfHYnKSdnZGZuYndqcGthRmppancnPycmY2NjY2NjJ3gl',
    };
    */
  const { authUser } = useAuth();
  const { businessFeedData, getBusinessFeedData } = useBusinessFeedData();

  const [options, setOptions] = useState(null);
  const { packageId } = useParams();

  const doCheckout = () => {
    stripeServices
      .initiateCheckout({
        user_token: authUser.user_token,
        packageId: packageId,
        requestOrigin: window.origin,
      })
      .then((res) => {
        console.log(res);
        setOptions({ clientSecret: res.clientSecret });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    doCheckout();
  }, []);

  return (
    <div className="card">
      <div className="card-body px-4">
        {/* <h1
          className={`card-title mt-4 ${
            businessFeedData?.business_profile.is_subscribed == 0
              ? "signup-heading"
              : "update-plan-heading"
          }`}
        >
          {businessFeedData?.business_profile.is_subscribed == 0
            ? "SIGN UP"
            : "UPGRADE NOW"}
        </h1> */}
        <h1
          className={`card-title mt-4 ${
            businessFeedData?.business_profile.is_subscribed == 0
              ? "signup-heading"
              : "update-plan-heading"
          }`}
        >
          {businessFeedData?.business_profile.is_subscribed == 0
            ? "SIGN UP"
            : "UPGRADE NOW"}
        </h1>

        <p class="card-text">You’re On The Right Path.</p>
        {options && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </div>
  );
}

export default StripeCheckoutComponent;
