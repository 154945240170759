import {
  EmailOutlined,
  PersonOutline,
  Web,
  PhoneIphoneOutlined,
  PinDropOutlined,
  Edit,
} from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";
import MapComponent from "../components/MapComponent";
import ChangePassword from "../components/CahngePassword";
import BusinessAdminLayout from "../layout/BusinessAdminLayout";

const LabelValue = ({ label, value }) => {
  return (
    <div className="input-group my-3">
      <span className={"input-group-text bg-white"}>{label}</span>
      <input className={"form-control border-start-0 p-3"} value={value} />
    </div>
  );
};

function BusinessProfilePage() {
  let { businessFeedData, getBusinessFeedData } = useBusinessFeedData();

  return (
    <BusinessAdminLayout>
      <div className="top-heading-row">
        <h1 class="web-page-heading">Settings</h1>
      </div>
      {/* <Link to={"/business/profile/plan"} className="btn btn-primary">
        Plan
      </Link> */}
      <div className="row">
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6">
          <div className="card">
            <div class="card-text-title">
              <h5 class="card-title">Business Settings</h5>
            </div>
            <div className="card-body">
              <div className="row row-cols-1">
                <div className="col">
                  <div className="sign-up-profile">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row mt-2">
            <div className="col-md-6">
              <h3 className="ph">My Profile</h3>
            </div>
            <div className="col-md-6">
              <Link
                to={"/business/profile/form"}
                className="btn btn-outline-dark float-end"
              >
                <Edit />{" "}
              </Link>
            </div>
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <div className="card">
            <img
              src={businessFeedData.business_profile.image}
              className="event-thumb-image rounded"
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <LabelValue
                label={<PersonOutline />}
                value={businessFeedData.business_profile.name}
              />
              <LabelValue
                label={<EmailOutlined />}
                value={businessFeedData.business_profile.email}
              />
              <LabelValue
                label={<PhoneIphoneOutlined />}
                value={businessFeedData.business_profile.phone}
              />
              <LabelValue
                label={<Web />}
                value={businessFeedData.business_profile.website}
              />
              <LabelValue
                label={<PinDropOutlined />}
                value={businessFeedData.business_profile.address}
              />
            </div>

            <div className="col-md-12">
              <MapComponent
                //lat="23.12968584459772"
                //lng="72.5411098985123"
                lat={businessFeedData.business_profile.latitude}
                lng={businessFeedData.business_profile.longitude}
                mapHeight={"300px"}
                markerId={"ahmedabad"}
              />
            </div>
            {/*
                            <div className="col-md-12">
                                <Link to={"/business/profile/form"} className="btn btn-primary btn-lg float-end" ><Edit/> Edit Profile</Link>
                            </div>
                            */}
          </div>
        </div>
      </div>
      {/* <ChangePassword /> */}
    </BusinessAdminLayout>
  );
}

export default BusinessProfilePage;
