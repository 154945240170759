import React from 'react'
import LeftNavigation from './LeftNavigation'

function BusinessAdminLayout({children}) {
    return (
        <section className="dashboard">
            <LeftNavigation/>
            <div className="page-wrapper">
                <div className="home-section">
                    {children}
                </div>
            </div>
        </section>
    )
}

export default BusinessAdminLayout