import React, { useEffect, useState } from "react";
import { Field, ErrorMessage } from "formik";
import { TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

/*  Text Input  */
const hasError = (formik, name) => {
  //console.log(formik.errors[name]);
  //console.log("touched", formik.touched);
  //console.log("formik",formik.values);
  if (formik.errors[name] !== undefined && formik.touched[name] !== undefined) {
    return { error: true, errorMsg: formik.errors[name] };
  } else {
    return { error: false, errorMsg: "" };
  }
};

export function MUInput(props) {
  const { label, name, formik, ...rest } = props;
  return (
    <>
      <Field name={name}>
        {(props) => {
          //console.log(props);
          const { field } = props; //{ field, form, meta }
          const { error, errorMsg } = hasError(formik, name);
          return (
            <>
              <TextField
                fullWidth
                {...rest}
                name={name}
                {...field}
                label={label}
                error={error}
                helperText={errorMsg}
              />
            </>
          );
        }}
      </Field>
    </>
  );
}



export function BSGInput(props) {
  const { label, name, formik, type, ...rest } = props;
  const [IType,setIType] = useState("text");

  useEffect(()=>{
        if(formik.values !== null && formik.values[name]!== undefined && formik.values[name]!==""){
            setIType(type)
        }
  },[formik])

  return (
    <>
      <Field name={name}>
        {(props) => {
          //console.log(props);
          const { field } = props; //{ field, form, meta }
          const { error, errorMsg } = hasError(formik, name);
          
          return (
            <>
              <div className="form-group">
                {/* <label className={error?"input-group-text bg-white border-danger text-danger":"input-group-text bg-white"}></label> */}
                {label}
                <input
                  {...rest}
                  className={error ? "form-control border-danger" : "form-control"}
                  type = {IType}
                  onFocus={()=>{
                        if(type == "date"){
                            setIType("date")
                        } else {
                            setIType(type)
                        }
                    }
                  }
                  onBlurCapture={()=>{
                        if(type == "date"){
                            setIType("date")
                        } else {
                            setIType(type)
                        }
                    }
                  }

                  {...field}
                />
              </div>

              {error && (
                <div className="text-danger invalid-feedback d-block">
                  {errorMsg}
                </div>
              )}
            </>
          );
        }}
      </Field>
    </>
  );
}
export function BSGInput_old(props) {
  const { label, name, formik, ...rest } = props;
  return (
    <>
      <Field name={name}>
        {(props) => {
          //console.log(props);
          const { field } = props; //{ field, form, meta }
          const { error, errorMsg } = hasError(formik, name);
          return (
            <>
              <div className="input-group ">
                <span
                  className={
                    error
                      ? "input-group-text bg-white border-danger text-danger"
                      : "input-group-text bg-white"
                  }
                >
                  {label}
                </span>
                <input
                  {...rest}
                  className={
                    error
                      ? "form-control border-danger border-start-0"
                      : "form-control border-start-0"
                  }
                  {...field}
                />
              </div>

              {error && <small className="text-danger">{errorMsg}</small>}
            </>
          );
        }}
      </Field>
    </>
  );
}
export function BSGPasswordInput(props) {
  const { label, name, formik, ...rest } = props;
  const [visible, setVisible] = useState(false);

  const handleVisiblity = () => {
    setVisible(!visible);
  };
  return (
    <>
      <Field name={name}>
        {(props) => {
          //console.log(props);
          const { field } = props; //{ field, form, meta }
          const { error, errorMsg } = hasError(formik, name);

          return (
            <>
              <div className="input-group ">
                <span
                  className={
                    error
                      ? "input-group-text bg-white border-danger text-danger"
                      : "input-group-text bg-white"
                  }
                >
                  {label}
                </span>
                <input
                  type={visible ? "text" : "password"}
                  className={
                    error
                      ? "form-control border-danger border-start-0 border-end-0"
                      : "form-control border-start-0 border-end-0"
                  }
                  {...field}
                />
                <button
                  className={
                    error
                      ? "btn btn-outline-danger border-start-0"
                      : "border rounded-end border-start-0"
                  }
                  onClick={handleVisiblity}
                  type="button"
                >
                  {visible ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </button>
              </div>

              {error && <small className="text-danger">{errorMsg}</small>}
            </>
          );
        }}
      </Field>
    </>
  );
}

export function BSTextarea(props) {
  const { label, name, formik, ...rest } = props;
  return (
    <>
      <Field name={name}>
        {(props) => {
          //console.log(props);
          const { field } = props; //{ field, form, meta }
          const { error, errorMsg } = hasError(formik, name);
          return (
            <>
              <div className="form-group">
                {label}
                <textarea
                  {...rest}
                  className={
                    error
                      ? "border-danger text-danger form-control"
                      : "form-control"
                  }
                  {...field}
                ></textarea>
              </div>
              {error && <div className="text-danger">{errorMsg}</div>}
            </>
          );
        }}
      </Field>
    </>
  );
}

export function InputBox(props) {
  const { label, name, formik, ...rest } = props;

  return (
    <>
      <Field name={name}>
        {(props) => {
          //console.log(props);
          const { field } = props; //{ field, form, meta }
          const { error, errorMsg } = hasError(formik, name);

          return (
            <>
              <input
                placeholder={label}
                style={{ maxWidth: "100%" }}
                type={"text"}
                className={
                  error
                    ? "p-0 border-danger border-start-0 border-end-0 border-top-0"
                    : "p-0 border-start-0 border-end-0 border-top-0"
                }
                {...field}
              />
              {error && <small className="text-danger">{errorMsg}</small>}
            </>
          );
        }}
      </Field>
    </>
  );
}
