import React, { useEffect, useState } from "react";
import BusinessAppLayout from "../../../layout/components/BusinessAppLayout";
import CircularProgress from "@mui/joy/CircularProgress";
import { Percent } from "@mui/icons-material";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";
import BusinessAdminLayout from "../layout/BusinessAdminLayout";
import ProgressCircle from "../components/ProgressCircle";
import ProgressLinear from "../components/ProgressLinear";
import StatisticsComponent from "../components/StatisticsComponent";
import UpcomingPlaceholder from "../components/UpcomingPlaceholder";
import businessEventServices from "../businessEventServices";
import { useAuth } from "../../../context/AuthContext";
import { Skeleton } from "@mui/material";
import moment from 'moment'

import {LinearProgress} from "@mui/material";




function BusinessStatisticsPage() {

    const authUser = useAuth();
    //const [ongoingEvents,setOngoingEvents] = useState(null)
    //const [upcommingEvents,setUpcommingEvents] = useState(null)
    const [allEvents,setAllEvents] = useState(null)
    const [loading,setLoadingStatus] = useState(false);
    
    
    /*
    let { businessFeedData } = useBusinessFeedData();
    useEffect(()=>{
        console.log(businessFeedData)
        setOngoingEvents(businessFeedData.event_list.ongoing)
    },[businessFeedData])
    */



    const getEventListWithStas = () =>{
        setLoadingStatus(true);
        let payload = {
            user_token : authUser.authUser.user_token,
            utc_date_time : moment().format("YYYY-MM-DD HH:mm:ss")
        }
        businessEventServices.getEventListWithStats(payload).then(res=>{
            //setOngoingEvents(res.data.event_list.ongoing)
            //setUpcommingEvents(res.data.event_list.upcomming)
            let _allEvents = []
            _allEvents.push(...res.data.event_list.ongoing);
            _allEvents.push(...res.data.event_list.upcomming);
            setAllEvents(_allEvents)

        }).catch(error=>{
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getEventListWithStas()
    },[])

    return (
    <BusinessAdminLayout>
        <div className="top-heading-row">
            <h1 class="web-page-heading">Statistics</h1>
        </div>
        {!loading ?
            <>
                {allEvents && allEvents.length > 0 && 
                    <>
                        {allEvents.map(eventDetails=><StatisticsComponent eventDetails={eventDetails} />)}
                        
                    </>
                }
                {allEvents == null && 
                    <UpcomingPlaceholder/>
                }
            </>
            :
            <div className="mt-4 text-center">
                <LinearProgress />
            </div>
        }

        
        
        
    </BusinessAdminLayout>
  );
}

export default BusinessStatisticsPage;


/*


<div className="card mb-2">
            <div class="card-text-title">
            <h5 class="card-title">Barge Festival</h5>
            </div>
            <div className="card-body">
            <div className="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 g-3">
                <div className="col d-flex">
                <div className="dashboard-cards">
                    <ul>
                    <li>
                        <ProgressCircle value={84} />
                    </li>
                    <li className="text-end">
                        <h4>
                        <i class="bx bx-user-circle"></i>
                        Going
                        </h4>
                        <h1>800</h1>
                        <p>40% Up This Event</p>
                    </li>
                    </ul>
                    <ProgressLinear value={23} />
                </div>
                </div>
                <div className="col d-flex">
                <div className="dashboard-cards">
                    <ul>
                    <li>
                        <ProgressCircle value={84} />
                    </li>
                    <li className="text-end">
                        <h4>
                        <img src="./assets/img/icons/users.svg" alt="" />
                        Coverage
                        </h4>
                        <h1>800</h1>
                        <p>23% Up This Event</p>
                    </li>
                    </ul>
                    <ProgressLinear value={23} />
                </div>
                </div>
                <div className="col d-flex">
                <div className="dashboard-cards">
                    <ul>
                    <li>
                        <ProgressCircle value={84} />
                    </li>
                    <li className="text-end">
                        <p style={{ color: "#D4D4D4" }}>
                        How many people have subscribed to your business
                        </p>
                        <h4>Subscribed</h4>
                        <h1>800</h1>
                        <p>3/7 Event Posts Used</p>
                    </li>
                    </ul>
                    <ProgressLinear value={23} />
                </div>
                </div>
                <div className="col d-flex">
                <img
                    src="http://3.105.164.38/public/uploads/event/20240514125708431166.png"
                    alt="event img"
                    class="img-fluid rounded-5"
                />
                </div>
                <div className="col d-flex">
                <div className="statistics-card">
                    <ul>
                    <li className="mb-3">
                        <h5>Age</h5>
                        <h2>AVE: 22</h2>
                    </li>
                    <li>
                        <div class="date-time-event">
                        <p>18-26</p>
                        <h1>01</h1>
                        </div>
                        <div class="date-time-event">
                        <p>18-26</p>
                        <h1>01</h1>
                        </div>
                        <div class="date-time-event">
                        <p>18-26</p>
                        <h1>01</h1>
                        </div>
                    </li>
                    </ul>
                </div>
                </div>
                <div className="col d-flex">
                <div className="statistics-card">
                    <ul>
                    <li className="mb-3">
                        <h5>Age</h5>
                        <h2>AVE: 22</h2>
                    </li>
                    <li>
                        <div class="date-time-event">
                        <p>18-26</p>
                        <h1>01</h1>
                        </div>
                        <div class="date-time-event">
                        <p>18-26</p>
                        <h1>01</h1>
                        </div>
                        <div class="date-time-event">
                        <p>18-26</p>
                        <h1>01</h1>
                        </div>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            </div>
        </div>


*/