import axios from 'axios';
const baseURL = process.env.REACT_APP_BASE_URL;

const API = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        //'Content-Type': 'application/x-www-form-urlencoded',
      },
});

API.interceptors.request.use(
    config => {
        if(localStorage.authUser){  // If user is Loggedin 
            let authUser = JSON.parse(localStorage.authUser);
            //let token = authUser.token;
            //config.headers.Authorization = `Bearer ${token}`;
            //config.headers['X-Requested-With'] = 1;
      } else {
        delete API.defaults.headers.common.Authorization;
      }
      return config;
    },
    error => Promise.reject(error)
);

API.interceptors.response.use(response=>{
    return response;
});

export default API;