import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";
import BusinessAdminLayout from "../layout/BusinessAdminLayout";
import ProgressCircle from "../components/ProgressCircle";
import moment from "moment";
import ProgressLinear from "../components/ProgressLinear";
import UpcomingPlaceholder from "../components/UpcomingPlaceholder";
import { useAuth } from "../../../context/AuthContext";
import businessEventServices from "../businessEventServices";


const EventStats =  ({eventDetails}) =>{
    const authUser = useAuth()
    
    const [stats,setStats] = useState(null);

    const getStats = () =>{
        console.log(eventDetails)
        setStats(eventDetails.statistics)
        /*let payload = {
            event_id : eventDetails.event_id,
            user_token : authUser.authUser.user_token,
            utc_date_time : moment().format("YYYY-MM-DD 00:00:00")
        }
        businessEventServices.getEventStats(payload).then(res=>{
            setStats(res.data.statistics)
            console.log(res.data);
        }).catch(error=>{

        }).finally(()=>{

        })
        */
    }

    useEffect(()=>{
        getStats();
    },[eventDetails])

    return (
        stats &&
        <li class="event-left-date-up">
            <div className="mb-4">
                <ProgressCircle value={stats.going.percentage} />
            </div>
            <ProgressLinear value={stats.going.percentageForEvent} />
            <p>{stats.going.percentageForEvent}% {stats.going.percentageForEvent >= 0?  "Up" : "Down"} This Event</p>
        </li>
    )
} 


const UpcomingEventsCardList = ({ eventList }) => {
  const navigate = useNavigate();

  const handleEventClick = (event_id) => {
    navigate("/business/events/form/" + event_id);
  };

  return (
    <>
      {eventList.map((event) => (
        <div class="card mb-2">
          <div class="card-text-title">
            <h5 class="card-title">{event.event_title}</h5>
          </div>
          <div class="card-body">
            <div class="upcoming-events-row">
              <div class="row g-4">
                <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 d-flex">
                  <ul class="upcoming-events-data">
                    <EventStats eventDetails={event} />
                    <li class="event-right-date-up">
                      <h5 class="card-subtitle">
                        <i class="bx bx-user-circle"></i> Going
                      </h5>
                      <h3 class="card-title">{event.statistics.going.numberOfAttendees}</h3>
                      <p class="card-text" style={{ overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis", width:130, }}>
                        {event.event_title} <br />
                        <span>
                          {moment(event.start_date).format("DD")}
                          <span>/</span>
                          {moment(event.start_date).format("MM")}
                          <span>/</span>
                          {moment(event.start_date).format("YY")}
                        </span>
                      </p>
                      <button
                        class="btn app-btn-fade app-btn px-5"
                        onClick={() => handleEventClick(event.event_id)}
                      >
                        edit
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="col-xxl-7 col-xl-8 col-lg-6 col-md-12 d-flex offset-xl-1">
                  <div class="img-for-event">
                    <img src={event.image} alt="event img" class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

function BusinessUpcomingEventsPage() {
  let { businessFeedData } = useBusinessFeedData();
  return (
    <BusinessAdminLayout>
      <div class="top-heading-row">
        <h1 class="web-page-heading">Upcoming Events</h1>
      </div>
      {businessFeedData && businessFeedData.event_list && (
        <div class="upcoming-events">
          {businessFeedData.event_list.upcomming.length > 0 && (
            <UpcomingEventsCardList
              eventList={businessFeedData.event_list.upcomming}
            />
          )}
          <UpcomingPlaceholder/>
        </div>
      )}
    </BusinessAdminLayout>
  );
}

export default BusinessUpcomingEventsPage;
